import React, { useState } from 'react';
import { Typography, TextField, Button, Container, Grid, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import Nav from '../../Components/Nav';
import Footer from '../Footer/Footer';
import './contact.css';

const ContactUs = () => {
  const { t } = useTranslation();
  const [emailData, setEmailData] = useState({ from: '', subject: '', text: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!emailData.from || !emailData.subject || !emailData.text) {
      setAlertMessage('لطفاً تمامی فیلدها را پر کنید');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    try {
      const response = await fetch('https://api.asiamehr.com/api/v1/contactemail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailData)
      });
      const data = await response.json();

      if (response.status === 200) {
        setAlertMessage('ایمیل با موفقیت ارسال شد.');
        setAlertSeverity('success');
      } else {
        setAlertMessage('ناموفق  ');
        setAlertSeverity('error');
      }
    } catch (err) {
      console.error(err);
      setAlertMessage('خطا در ارسال اطلاعات');
      setAlertSeverity('error');
    } finally {
      setAlertOpen(true);
    }
  };

  return (
    <div>
      <Nav />
      <div className="contact-container">
        <Typography variant="body1" sx={{ marginTop: '50px' }} className="contact-heading">
          {t('contact.contact_heading')}
        </Typography>
        <Container maxWidth="md" className="contact-form-container">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <div className="contact-form">
                <form onSubmit={sendEmail}>
                  <Typography variant="h3" className="form-heading">
                    {t('contact.form_heading')}
                  </Typography>
                  <div className="form-group">
                    <TextField
                      name="subject"
                      placeholder={t('contact.name')}
                      value={emailData.subject}
                      onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                      variant="outlined"
                      sx={{
                        '& .MuiFormHelperText-root': {
                          direction: 'rtl',
                          textAlign: 'right'
                        }
                      }}
                      autoComplete="off"
                      fullWidth
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      name="from"
                      placeholder={t('contact.email_label')}
                      value={emailData.from}
                      onChange={(e) => setEmailData({ ...emailData, from: e.target.value })}
                      variant="outlined"
                      sx={{
                        '& .MuiFormHelperText-root': {
                          direction: 'rtl',
                          textAlign: 'right'
                        }
                      }}
                      autoComplete="off"
                      fullWidth
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      id="message"
                      placeholder={t('contact.message_label')}
                      multiline
                      rows={4}
                      value={emailData.text}
                      onChange={(e) => setEmailData({ ...emailData, text: e.target.value })}
                      variant="outlined"
                      fullWidth
                      sx={{
                        '& .MuiFormHelperText-root': {
                          direction: 'rtl',
                          textAlign: 'right'
                        }
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="submit-button">
                    <Button variant="contained" color="primary" type="submit">
                      {t('contact.send_button')}
                    </Button>
                  </div>
                </form>
              </div>
            </Grid>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
              <MuiAlert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
            <Grid item xs={12} sm={6}>
              <div className="map-container">
                <iframe
                  title={t('contact.map_title')}
                  width="100%"
                  height="450"
                  loading="lazy"
                  allowFullScreen
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3229.6604267017588!2d59.334701!3d35.955276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDU3JzE5LjAiTiA1OcKwMjAnMDQuOSJF!5e0!3m2!1sen!2s!4v1712749110672!5m2!1sen!2s"
                  style={{ border: 'none', borderRadius: '4px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                ></iframe>
              </div>
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center', padding: '20px', marginTop: '20px' }}>
            <Typography variant="h4">{t('contact.title')}</Typography>
            <div className="info">
              <Grid container spacing={2} justify="center">
                <Grid item xs={12} sm={6} md={4} sx={{ marginTop: '20px' }}>
                  <LocationOnIcon style={{ marginRight: '5px' }} />
                  <Typography variant="body1">
                    {t('contact.address_line2')}
                    <br />
                    {t('contact.address')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CallIcon style={{ marginRight: '5px' }} />
                  <Typography variant="body1">{t('contact.office')}</Typography>
                 
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <EmailIcon style={{ marginRight: '5px' }} />
                  <Typography variant="body1">asiamehrfs@gmail.com</Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
