import React, { useState, useEffect, useMemo } from "react";

import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Auth/Login";
import Footer from "./Pages/Footer/Footer";
import Register from "./Pages/Auth/Register";
import Panel from "./Components/Pane/panel";
import Gallery from "./Pages/gallery/gallery";
import { AuthContext } from "./utils/AuthContext";
import Nav from "./Components/Nav";
import SliderPanel from "./Components/Pane/SliderPanel";
import AdminGallery from "./Components/Pane/AdminGallery";
import Users from "./Components/Pane/Users";

import EmailForm from "./Components/Pane/EmailForm";
import EmployeeDashboard from "./Components/employepanel/Employe";
import Employees from "./Components/employepanel/Employe";
import PanelSlidbar from "./Components/Pane/PanelSlidebar";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react";
import Error404 from "./Pages/Erorr/Error";

import About from "./Pages/about/About";
import ContactUs from "./Pages/contact/Contact";
import SliderChange from "./Components/Pane/Slider/deletSlider";
import GalleryUpdate from "./Components/Pane/Slider/GalleryUpdate";
import CartPanel from "./Components/Pane/Slider/CartPanel";
import CartChange from "./Components/Pane/Slider/CartUpdate";
import EmployeePanel from "./Components/employepanel/EmployeePanel";
import Project from "./Pages/Projects/Project";
import Qrcode from "./Pages/Main/Qrcode";
import ForgetPass from "./Pages/Auth/ForgetPass";
import Password from "./Pages/Auth/Password";
import GalleryPage from "./Components/Pane/Slider/GalleryPage";
import GalleryPageUpdate from "./Components/Pane/Slider/GalleryPageUpdate";
import projectUpdate from "./Components/Pane/Slider/ProjectUpdate";
import Projectt from "./Components/Pane/Slider/Project";

import FiestGallery from "./Components/Pane/Slider/FiestGallery";
import GalleryG from "./Components/Pane/Slider/DeleteG";
import UserRegister from "./Components/Pane/UserRegister";
import DeleteUser from "./Components/Pane/Userss/DeleteUser";
import UpdateUser from "./Components/Pane/Userss/UpdateUser";
import AdminPermissions from "./Components/Pane/Permission";
import GetOneLetter from "./Pages/ExternalLetter/getOne";
import GetAllLetter from "./Pages/ExternalLetter/getAll";
import ReceiveLetter from "./Pages/ExternalLetter/recieve";
import TerminatedLetter from "./Pages/ExternalLetter/terminated";
import CreateExternalLetter from "./Pages/ExternalLetter/create";
import ArchiveExternalLetter from "./Pages/ExternalLetter/Archive";
import { Toaster } from "react-hot-toast";
import CreateEnternalLetter from "./Pages/internalLetter/create";
import GetAllInternalLetter from "./Pages/internalLetter/getAll";
import ReceiveInternalLetter from "./Pages/internalLetter/recieve";
import GetOneInternalLetter from "./Pages/internalLetter/getOne";
import ExternalSearch from "./Pages/ExternalLetter/Search";
import InternalSearch from "./Pages/internalLetter/Search";
import ExternalSendLetter from "./Pages/ExternalLetter/Send";
import EnternalSendLetter from "./Pages/internalLetter/Send";
import GetAllTranscript from "./Pages/Transcript/getAll";
import SentTransscripte from "./Pages/Transcript/sent";
import Recivedtranscript from "./Pages/Transcript/received";
import CreateProcess from "./Pages/Process/create";
import UpdateProcess from "./Pages/Process/update";
import GetOneProcess from "./Pages/Process/getOne";
import CreateRequest from "./Pages/Request/Create";
import MyRequest from "./Pages/Request/MyRequest";
import AllRequests from "./Pages/Request/AllRequests";
import CompleteRequests from "./Pages/Request/Complete";
import PendingRequest from "./Pages/Request/Pending";
import RequestSearch from "./Pages/Request/Search";
import MyForgotPassword from "./Pages/Auth/Pass";
function App() {
  const [loginUser, setLoginUser] = useState(null);
  let data = localStorage.getItem("loginUser");
  useEffect(() => {
    data && setLoginUser(JSON.parse(data));
  }, []);

  return (
    <div>
      {/* <Nav /> */}
      {/* <ThemeProvider theme={theme}> */}

      <AuthContext.Provider value={{ loginUser, setLoginUser }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact={true} element={<Home />} />
            <Route path="/about" exact={true} element={<About />} />
            {/* <Route path="/register" exact={true} element={<Register />} /> */}
            <Route path="/contact" exact={true} element={<ContactUs />} />
            <Route path="/login" exact={true} element={<Login />} />
            {/* <Route
              path="/forget-password"
              exact={true}
              element={<ForgetPass />}
            /> */}

<Route path="/forget-password" exact={true} element={<MyForgotPassword />} />
            {/* <Route path="/password" exact={true} element={<Password />} /> */}
            <Route path="/panel" exact={true} element={<Panel />} />
            <Route path="/project" exact={true} element={<Project />} />
            <Route
              path="/panel/slider"
              exact={true}
              element={<SliderPanel />}
            />
            <Route
              path="/panel/slider/delete"
              exact={true}
              element={<SliderChange />}
            />
            <Route path="/panel/cart" exact={true} element={<CartPanel />} />
            <Route
              path="/panel/cart/delete"
              exact={true}
              element={<CartChange />}
            />
            <Route
              path="/panel/gallery"
              exact={true}
              element={<AdminGallery />}
            />
            <Route
              path="/panel/gallery/delete"
              exact={true}
              element={<GalleryUpdate />}
            />
            <Route path="/panel/email" exact={true} element={<EmailForm />} />
            <Route path="/panel/users" exact={true} element={<Users />} />
            <Route
              path="/panel/sendemail"
              exact={true}
              element={<EmailForm />}
            />
            {loginUser?.token && (
              <Route path="/panel/external-letter">
                <Route
                  path="all"
                  element={
                    loginUser?.role !== "admin" &&
                    loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/external-letter/sent"} />
                    ) : (
                      <GetAllLetter />
                    )
                  }
                />
                <Route
                  path="receive"
                  element={
                    loginUser?.role !== "admin" &&
                    loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/external-letter/sent"} />
                    ) : (
                      <ReceiveLetter />
                    )
                  }
                />
                <Route path="terminated" element={<TerminatedLetter />} />
                <Route path="sent" element={<ExternalSendLetter />} />
                <Route
                  path="archive"
                  element={
                    loginUser?.role !== "admin" &&
                    loginUser?.role !== "superAdmin" &&
                    loginUser?.role !== "archive" ? (
                      <Navigate to={"/panel/external-letter/sent"} />
                    ) : (
                      <ArchiveExternalLetter />
                    )
                  }
                />
                <Route path=":id" element={<GetOneLetter />} />
                <Route path="add" element={<CreateExternalLetter />} />
                <Route path="search" element={<ExternalSearch />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/internal-letter">
                <Route path="sent" element={<EnternalSendLetter />} />
                <Route
                  path="all"
                  element={
                    loginUser?.role !== "admin" &&
                    loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/internal-letter/sent"} />
                    ) : (
                      <GetAllInternalLetter />
                    )
                  }
                />
                <Route path="receive" element={<ReceiveInternalLetter />} />
                <Route path="add" element={<CreateEnternalLetter />} />
                <Route path=":id" element={<GetOneInternalLetter />} />
                <Route path="search" element={<InternalSearch />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/transcript">
                <Route
                  path="all"
                  element={
                    loginUser?.role !== "admin" &&
                    loginUser?.role !== "superAdmin" ? (
                      <Navigate to={"/panel/transcript/sent"} />
                    ) : (
                      <GetAllTranscript />
                    )
                  }
                />
                <Route path="sent" element={<SentTransscripte />} />
                <Route path="receive" element={<Recivedtranscript />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/process">
                <Route path="create" element={<CreateProcess />} />
                <Route path="update" element={<UpdateProcess />} />
                <Route path=":id" element={<GetOneProcess />} />
              </Route>
            )}
            {loginUser?.token && (
              <Route path="/panel/request">
                <Route path="create" element={<CreateRequest />} />
                <Route path="all" element={<AllRequests />} />
                <Route path="pending" element={<PendingRequest />} />
                <Route path="complete" element={<CompleteRequests />} />
                <Route path="myRequest" element={<MyRequest />} />
                <Route path="search" element={<RequestSearch />} />
              </Route>
            )}
            <Route path="/gallery" exact={true} element={<Gallery />} />
            <Route path="/galleryG" exact={true} element={<GalleryUpdate />} />
            <Route path="/employee" exact={true} element={<EmployeePanel />} />

            <Route path="/panel/gallerypage" element={<GalleryPage />} />
            <Route
              path="/panel/gallerypage/delete"
              element={<GalleryPageUpdate />}
            />
            <Route path="/panel/projectt" element={<Projectt />} />
            <Route path="/panel/permission" element={<AdminPermissions />} />
            <Route path="/panel/projectt/delete" element={<projectUpdate />} />
            <Route path="/panel/gallery/deletepic" element={<GalleryG />} />
            <Route path="/panel/register" element={<UserRegister />} />
            <Route path="/panel/register/deleteuser" element={<DeleteUser />} />
            <Route path="/panel/register/updateuser" element={<UpdateUser />} />
            <Route path="*" element={<Error404 />} />
            {/* <Route path="/qrcode" exact={true} element={<Qrcode/>} /> */}
          </Routes>
          <Toaster />
        </BrowserRouter>
      </AuthContext.Provider>

      {/* </ThemeProvider > */}
    </div>
  );
}

export default App;
