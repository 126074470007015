import React, { useEffect, useState ,useContext } from 'react';
import { useParams } from "react-router-dom";
import './update.css'
import { AuthContext } from '../../../utils/AuthContext';
import SliderPanel from '../SliderPanel';
import PanelSidebar from '../PanelSlidebar';
import { Navigate } from 'react-router-dom';
function GalleryUpdate() {
  const [galleryData, setGalleryData] = useState([]);
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext)
  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/gallery`)
      .then((res) => res.json())
      .then((data) => setGalleryData(data.data))
      .catch((err) => console.log(err));
  }, [id]);

  const deleteItem = (id) => {
    fetch(`https://api.asiamehr.com/api/v1/gallery/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setGalleryData(galleryData.filter(gallery => gallery._id !== id));
      })
      .catch((err) => console.log(err));
      
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div className='myslider-panel'>
      <h1> حذف کردن اطلاعات </h1>
      <PanelSidebar/>
      {galleryData?.map(gallery => (
        <div className='slide' key={gallery._id}>
          <h3>{gallery.title}</h3>
          <img src={`https://api.asiamehr.com${gallery.image}`} alt={gallery.title} />
          <p>{gallery.description}</p>
          <button onClick={() => deleteItem(gallery._id)}>حذف</button>
        </div>
      ))}
    </div>
  );
}

export default GalleryUpdate;
