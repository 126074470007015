import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Stack,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  useMediaQuery,
  InputAdornment,
  Modal,
  Button,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import PanelSidebar from "../../../Components/Pane/PanelSlidebar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { AuthContext } from "../../../utils/AuthContext";
import Loader from "../../../Components/Loader";
import SearchIcon from "@mui/icons-material/Search";
import TextsmsIcon from "@mui/icons-material/Textsms";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export const TableItems = ({
  id,
  letterId,
  pdf,
  receiver,
  sender,
  letterType,
  content,
  letter,
}) => {
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openF, setOpenF] = useState(false);
  const handleOpenF = () => setOpenF(true);
  const handleCloseF = () => setOpenF(false);
  const [letters, setLetters] = useState(null);
  const extractContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    let paragraphs = tempDiv.querySelectorAll("p");
    let result = "";

    paragraphs.forEach((p) => {
      result += p.textContent + "\n";
    });

    return result;
  };
  const handleLetter = ({ letterType, letterId }) => {
    fetch(
      `https://api.asiamehr.com/api/v1/${letterType === "برون سازمانی" ? "externalLetter" : "internalLetter"
      }/${letterId}?populate=sender`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log(data.data);
          setLetters(data?.data);
        }
      });
  };
  const isMobile = useMediaQuery("(max-width:900px)");
  return (
    <>
      <TableRow>
        <TableCell
          align="right"
          style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "nowrap",
          }}
        >
          {pdf ? (
            <Tooltip title="دانلود فایل pdf">
              <IconButton
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
                href={`https://api.asiamehr.com/uploads/${pdf}`}
                download
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip title="مشاهده نامه">
            <IconButton
              style={{ color: "black", margin: "0 10px" }}
              onClick={() => {
                handleOpen();
                handleLetter({ letterId: letterId, letterType: letterType });
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="مشاهده رونوشت">
            <IconButton
              style={{ color: "black", margin: "0 10px" }}
              onClick={handleOpenF}
            >
              <TextsmsIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        {receiver && <TableCell align="right">{receiver}</TableCell>}
        {sender && <TableCell align="right">{sender} </TableCell>}
        {letterType && <TableCell align="right">{letterType} </TableCell>}
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {letters ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              width: isMobile ? "80%" : 900,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Stack
              width={"100%"}
              height={"100%"}
              flexDirection={"column"}
              gap={"20px"}
              borderRadius={"20px"}
            >
                <Grid
                  container
                  py={"10px"}
                  px={1}
                  justifyContent={"space-between"}
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: "#F9F9F9",
                    boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                    direction:"ltr"
                  }}
                >
                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      fontSize={{ xs: "14px", xl: "18px" }}
                      variant="h5"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      موضوع: {letters?.subject}
                    </Typography>
                  </Grid>

                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      fontSize={{ xs: "14px", xl: "18px" }}
                      variant="h5"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      شماره نامه: {letters?.letterId}
                    </Typography>
                  </Grid>

                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      fontSize={{ xs: "14px", xl: "18px" }}
                      variant="h5"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      تاریخ: {letters?.letterDate}
                    </Typography>
                  </Grid>

                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      fontSize={{ xs: "14px", xl: "18px" }}
                      variant="h5"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      فرستنده: {letters?.sender?.fullName}
                    </Typography>
                  </Grid>

                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      fontSize={{ xs: "14px", xl: "18px" }}
                      variant="h5"
                      sx={{ color: "black", textAlign: "right" }}
                    >
                      گیرنده: {letterType === "برون سازمانی" ? letters?.receiver : letters?.receiver?.fullName}
                    </Typography>
                  </Grid>

                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {/* اگر به آیتم اضافی نیاز دارید، می‌توانید اینجا اضافه کنید */}
                  </Grid>
                </Grid>
              <Typography
                py={"20px"}
                lineHeight={"40px"}
                px={"5%"}
                sx={{
                  borderRadius: "15px",
                  fontSize: isMobile ? "12px" : "18px",
                  direction: "rtl",
                  textAlign: "end !important",
                  backgroundColor: "#F9F9F9",
                  boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                  whiteSpace: "pre-wrap",
                  minHeight:"300px"
                }}
              >
                {extractContent(letters?.content)}
              </Typography>
            </Stack>
          </Box>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal
        open={openF}
        onClose={handleCloseF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: isMobile ? "80%" : 800,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Stack
            width={"100%"}
            height={"100%"}
            flexDirection={"column"}
            gap={"20px"}
            borderRadius={"20px"}
          >
            <Stack
              flexDirection={"row-reverse"}
              flexWrap={"wrap"}
              p={2}
              gap={4}
              justifyContent={"end"}
              sx={{
                borderRadius: "15px",
                backgroundColor: "#F9F9F9",
                boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
              }}
            >
              <Typography
                fontSize={isMobile ? "14px" : "18px"}
                variant="h6" // Changed to h6 for better hierarchy
                sx={{ color: "black" }}
              >
                نوع نامه : {letterType}
              </Typography>
              <Typography
                fontSize={isMobile ? "14px" : "18px"}
                variant="h6" // Changed to h6 for better hierarchy
                sx={{ color: "black" }}
              >
                فرستنده : {sender}
              </Typography>
              <Typography
                fontSize={isMobile ? "14px" : "18px"}
                variant="h6" // Changed to h6 for better hierarchy
                sx={{ color: "black" }}
              >
                گیرنده : {receiver}
              </Typography>
            </Stack>
            <Typography
              py={"20px"}
              lineHeight={"40px"}
              px={"5%"}
              sx={{
                borderRadius: "15px",
                fontSize: isMobile ? "12px" : "18px",
                direction: "rtl",
                textAlign: "end !important",
                backgroundColor: "#F9F9F9",
                boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                minHeight:"300px"
              }}
            >
              {content}
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default function GetAllInternalLetter() {
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [letters, setLetters] = useState(null);
  const [sort, setSort] = useState("-createdAt");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const isMobile = useMediaQuery("(max-width:900px)");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `https://api.asiamehr.com/api/v1/transcript?populate=senderId,receiverId&sort=${sort}&page=${page}&limit=${10}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        console.log(data);
        if (data.success) {
          setLetters(data.data);
          setCount(Math.ceil(data.count / 10));
        } else {
          alert("اشکال در برقراری اتصال");
        }
      } catch (err) {
        alert("اشکال در برقراری اتصال");
      }
    };
    fetchData();
  }, [token, sort, page]);

  const items = letters?.map((e, index) => (
    <TableItems
      key={index}
      id={e._id}
      pdf={e?.pdf}
      letterType={
        e.letterType === "ExternalLetter" ? "برون سازمانی" : "درون سازمانی"
      }
      letterId={e?.letterId}
      sender={e?.senderId?.fullName}
      receiver={e?.receiverId?.fullName}
      content={e?.description}
      letter={e?.letterId}
    />
  ));

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              overflowX: "scroll",
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0,
            }}
          >
            {letters ? (
              <>
                <CacheProvider value={cacheRtl}>
                  <Stack
                    flexDirection={"row"}
                    pt={"20px"}
                    sx={{ direction: "rtl !important" }}
                  >
                    <Stack
                      dir="rtl"
                      width={"100%"}
                      justifyContent={"space-between"}
                      flexDirection={isMobile ? "column" : "row"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: isMobile ? "100%" : "250px",
                        }}
                      >
                        <TextField
                          placeholder="جستجو"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            py: "3.5px",
                            backgroundColor: "white",
                            boxShadow: "0px 0px 10px 2px rgba(255,255,255,.5)",
                            borderRadius: "15px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                            },
                          }}
                          dir="rtl"
                        />
                      </Box>

                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        onChange={(e) => setSort(e.target.value)}
                        placeholder="مرتب سازی بر اساس"
                        sx={{
                          width: isMobile ? "100%" : "270px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                        defaultValue={"s"}
                      >
                        <MenuItem dir="rtl" value="s">
                          مرتب سازی بر اساس
                        </MenuItem>
                        <MenuItem dir="rtl" value="-createdAt">
                          جدید ترین نامه ها
                        </MenuItem>
                        <MenuItem dir="rtl" value="createdAt">
                          قدیمی ترین نامه ها
                        </MenuItem>
                      </TextField>
                    </Stack>
                  </Stack>

                  <TableContainer
                    sx={{
                      marginTop: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      overflowX: "auto", // اضافه شده برای اسکرول افقی
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        direction: "rtl",
                        minWidth: "700px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            عملیات
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            گیرنده
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            فرستنده
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            نوع نامه
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items && items.length > 0 ? (
                          items
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={5}
                              align="center"
                              sx={{
                                padding: "20px",
                                fontStyle: "italic",
                                color: "#888",
                              }}
                            >
                              نامه ای موجود نیست
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    siblingCount={0}
                    boundaryCount={0}
                    defaultPage={1}
                    count={count}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    color="primary"
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                      display: "flex",
                      "& .MuiPaginationItem-root": {
                        bgcolor: "white",
                        borderRadius: 2,
                        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.2)",
                        "&:hover": {
                          bgcolor: "grey.200",
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      },
                    }}
                  />
                </CacheProvider>
              </>
            ) : (
              <Loader />
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
