import React from 'react'

function Error404() {
  return (
    <div>
      page not found 404
    </div>
  )
}

export default Error404
