import React, { useContext, useState, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Box,
  Button,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Alert,
  MenuItem,
  FormControl,
  Select,
  Grid,
  InputLabel,
} from "@mui/material";
import { AuthContext } from "../../utils/AuthContext";
import PanelSidebar from "./PanelSlidebar";
import SignatureCanvas from "react-signature-canvas";

export default function UserRegister() {
  const [errors, setErrors] = useState({});

  const { loginUser } = useContext(AuthContext);
  const [fields, setFields] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    profilePic: "",
    personalCode: "",
    signature: "",
    employeePosition: "",
    gender: "male",
    role: "employee", // Default role
  });
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const sigCanvas = useRef({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFields({ ...fields, profilePic: file });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("fullName", fields.fullName);
    formData.append("email", fields.email);
    formData.append("personalCode", fields.personalCode);
    formData.append("phone", fields.phone);
    formData.append("password", fields.password);
    formData.append("address", fields.address);
    formData.append("employeePosition", fields.employeePosition);
    formData.append("file", fields.profilePic);
    formData.append("role", fields.role);
    formData.append("gender", fields.gender);
    formData.append("signature", fields.signature);

    try {
      const response = await fetch(
        "https://api.asiamehr.com/api/v1/user/register",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSnack({
          open: true,
          message: "حساب کاربری شما ایجاد شد! لطفا وارد حساب خود شوید.",
          severity: "success",
        });
      } else {
        const errorData = await response.json();
        let errorMessage = "مشکلی در ایجاد حساب کاربری پیش آمده است.";
        if (errorData.message.includes("personalCode")) {
          errorMessage = "این کد‌پرسنلی قبلاً ثبت شده است.";
        } else if (errorData.message.includes("phone")) {
          errorMessage = "این شماره تلفن قبلاً ثبت شده است.";
        } else if (errorData.message.includes("fullName")) {
          errorMessage = "این نام کامل قبلاً ثبت شده است.";
        } else if (errorData.message.includes("email")) {
          errorMessage = "این ایمیل قبلاً ثبت شده است.";
        }
        setSnack({ open: true, message: errorMessage, severity: "error" });
      }
    } catch (error) {
      setSnack({
        open: true,
        message: "مشکلی در ایجاد حساب کاربری پیش آمده است.",
        severity: "error",
      });
    }
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  if (!loginUser?.token) return <Navigate to="/login" />;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
      }}
    >
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="flex-end"
      >
        <PanelSidebar />
        <Stack
          sx={{
            width: { xs: "100%", md: "80%" },
            minHeight: "100vh",
            marginTop: 10,
            marginRight: { xs: 0, md: 35 },
            padding: { xs: 2, md: 0 },
          }}
        >
          <Box
            sx={{
              padding: "50px 5%",
              borderRadius: "20px",
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Typography
              variant={{ xs: "h5", md: "h4" }}
              my="20px"
              color="gainsboro"
            >
              ثبت نام
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="fullName"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    نام و نام خانوادگی
                  </InputLabel>
                  <TextField
                    name="fullName"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& input": {
                        textAlign: "right !important",
                        fontSize: "16px",
                      },
                      direction: "rtl",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="email"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    ایمیل
                  </InputLabel>
                  <TextField
                    name="email"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& input": {
                        textAlign: "right !important",
                        fontSize: "16px",
                      },
                      direction: "rtl",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="personalCode"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    کد پرسنلی
                  </InputLabel>
                  <TextField
                    name="personalCode"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& input": {
                        textAlign: "right !important",
                        fontSize: "16px",
                      },
                      direction: "rtl",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="phone"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    موبایل
                  </InputLabel>
                  <TextField
                    name="phone"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& input": {
                        textAlign: "right !important",
                        fontSize: "16px",
                      },
                      direction: "rtl",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="password"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "16px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    حداقل تعداد کاراکترهای رمز عبور ۸ کاراکتر است
                  </InputLabel>
                  <TextField
                    name="password"
                    type="password"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& input": {
                        textAlign: "right !important",
                        fontSize: "16px",
                      },
                      direction: "rtl",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="address"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    آدرس
                  </InputLabel>
                  <TextField
                    name="address"
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      "& input": {
                        textAlign: "right !important",
                        fontSize: "16px",
                      },
                      direction: "rtl",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="gender"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    جنسیت
                  </InputLabel>
                  <Select
                    name="gender"
                    value={fields.gender}
                    onChange={handleChange}
                    fullWidth
                    displayEmpty
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      direction: "rtl",
                      textAlign: "right !important",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  >
                    <MenuItem value="male">آقا</MenuItem>
                    <MenuItem value="female">خانم</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="role"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    نقش
                  </InputLabel>
                  <Select
                    name="role"
                    value={fields.role}
                    onChange={handleChange}
                    fullWidth
                    displayEmpty
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      direction: "rtl",
                      textAlign: "right !important",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  >
                    <MenuItem value="admin">ادمین</MenuItem>

                    <MenuItem value="superAdmin">سوپر ادمین</MenuItem>
                    <MenuItem value="employee">کارمند</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="role"
                    sx={{
                      left: "unset",
                      right: "50%",
                      transform: "translateX(50%)",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "18px",
                      "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                  >
                    سمت
                  </InputLabel>
                  <Select
                    name="employeePosition"
                    value={fields.employeePosition}
                    onChange={handleChange}
                    fullWidth
                    displayEmpty
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      direction: "rtl",
                      textAlign: "right !important",
                      boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    }}
                  >
                    <MenuItem value="مدیر عامل">مدیر عامل</MenuItem>
                    <MenuItem value="رییس هیت مدیره">رییس هیت مدیره</MenuItem>
                    <MenuItem value="نایب رییس هیت مدیره">
                      نایب رییس هیت مدیره
                    </MenuItem>
                    <MenuItem value="بازرس">بازرس</MenuItem>
                    <MenuItem value="مدیر مالی">مدیر مالی</MenuItem>
                    <MenuItem value="مدیر دفتری">مدیر دفتری</MenuItem>
                    <MenuItem value="مدیر حقوقی">مدیر حقوقی</MenuItem>
                    <MenuItem value="مدیر فنی">مدیر فنی</MenuItem>
                    <MenuItem value="مدیر رسانه">مدیر رسانه</MenuItem>
                    <MenuItem value="سرپرست کارگاه">سرپرست کارگاه</MenuItem>
                    <MenuItem value="کارگر">کارگر</MenuItem>
                    <MenuItem value="نگهبان">نگهبان</MenuItem>
                    <MenuItem value="پشتیبان سایت">پشتیبان سایت</MenuItem>
                    <MenuItem value="منابع انسانی">منابع انسانی</MenuItem>
                    <MenuItem value="مدیر تحقیقاتی">مدیر تحقیقاتی</MenuItem>
                    <MenuItem value="مشاور">مشاور</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: "#2e7d32", color: "#fff" }}
                >
                  بارگذاری تصویر
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  تصویر امضا
                </Typography>
                <input
                  type="file"
                  accept="image/*"
                  name="signature"
                  onChange={(e) =>
                    setFields({ ...fields, signature: e.target.files[0] })
                  }
                  required
                  style={{
                    marginTop: "16px",
                    marginBottom: "16px",
                    display: "block",
                    textAlign: "right",
                  }}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ textAlign: 'right', color: 'gainsboro' }}>
                  امضا:
                </Typography>
                <Box sx={{ border: '2px dashed white', borderRadius: '15px', padding: '15px' }}>
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      width: 300,
                      height: 100,
                      className: 'sigCanvas',
                    }}
                    backgroundColor="#fff"
                    penColor="black"
                  />
                  <Button onClick={clearSignature} sx={{ mt: 1, backgroundColor: '#2e7d32', color: '#fff' }}>
                    پاک کردن
                  </Button>
                </Box>
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  onClick={handleRegister}
                  variant="contained"
                  sx={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#2e7d32",
                    color: "white",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#27632a",
                    },
                  }}
                >
                  ثبت نام
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Stack>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert severity={snack.severity} sx={{ width: "100%" }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
