import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
  Modal,
  IconButton,
  Grid,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  CircularProgress,
  DialogActions,
  styled,
  stepConnectorClasses,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { StepConnector } from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
import PanelSidebar from "../../../Components/Pane/PanelSlidebar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useContext } from "react";
import { AuthContext } from "../../../utils/AuthContext";
import Loader from "../../../Components/Loader";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import notify from "../../../utils/hotToast";
import ReplayIcon from "@mui/icons-material/Replay";
import { CloseFullscreen } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { json } from "react-router-dom";
import { Link } from "react-router-dom";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export const TableItems = ({
  allSteps,
  currentStepIndex,
  currentStep,
  status,
  receiver,
  requestType,
  content,
  processId,
  requestId,
  requestDate,
  sender,
  steps,
  attachment,
  index,
  pdf,
}) => {
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [open, setOpen] = useState(false);
  const [openF, setOpenF] = useState(false);
  const [openRequestDetails, setOpenRequestDetails] = useState(false);
  const [process, setProcess] = useState(null);
  const isMobile = useMediaQuery("(max-width:900px)");
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(currentStepIndex - 1);
  const [allStep, setAllStep] = useState(allSteps);
  const [currentIndex, setCurrentIndex] = useState(currentStepIndex);
  const [file, setFile] = useState();
  const [id, setId] = useState(loginUser?.id);
  const [message, setMessage] = useState("نظری ثبت نشده ...");
  const [openDialog, setOpenDialog] = useState(false);
  const [value, setValue] = useState(null);
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState();
  const [isFainally,setIsFainally] = useState(false)

  let stepNumber = 1;
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleChange = (event, newValue, index) => {
    setValue(newValue);
    handleUserSelect(index, newValue);
  };

  const handleUserSelect = (index, newValue) => {
    const updatedUsers = [...users];
    updatedUsers[index] = {
      stepNumber: index + 1,
      userId: newValue?._id || null,
    };
    setUsers(updatedUsers);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseF = () => setOpenF(false);
  const extractContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let paragraphs = tempDiv.querySelectorAll("p");
    let result = "";
    paragraphs.forEach((p) => {
      result += p.textContent + "\n";
    });
    return result;
  };
  useEffect(() => {
    setLoading(true);
    fetch(`https://api.asiamehr.com/api/v1/user-letter`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOptions(data?.data?.users || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  const calculatedSteps = useRef(false);

  useEffect(() => {
    console.log(steps, currentStep, allStep, currentIndex);
    if (calculatedSteps.current) return; // جلوگیری از اجرای مجدد

    let updatedAllStep = allStep;
    let updatedCurrentIndex = currentIndex;

    steps?.forEach((e) => {
      if (e?.referralId) {
        updatedAllStep += 2;
        updatedCurrentIndex += 2;
      }
    });

    if (currentStep?.referralId) {
      updatedAllStep += 2;
      updatedCurrentIndex += 1;
    }

    if (currentStep?.referralContent) {
      updatedCurrentIndex += 1;
    }

    // به‌روزرسانی تنها در صورت نیاز
    if (updatedAllStep !== allStep) {
      setAllStep(updatedAllStep);
    }

    if (updatedCurrentIndex !== currentIndex) {
      setCurrentIndex(updatedCurrentIndex);
    }
    if(currentStepIndex===steps.length&&currentStep == null){
      setIsFainally(true)
    }
    // علامت گذاری محاسبات به عنوان انجام شده
    calculatedSteps.current = true;
  }, [steps, currentStep, allStep, currentIndex]);
  const getSteps = async (proccessId) => {
    const res = await fetch(
      `https://api.asiamehr.com/api/v1/process/${proccessId}?populate=steps.userId`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    if (data.success) {
      setProcess(data?.data?.process[0]);
    }
  };
  const acceptFunc = async (event, requestId) => {
    event.preventDefault();
    setAcceptLoading(true);

    try {
      const formData = new FormData();

      // مقدار message را داخل تگ <p> قرار دهید
      const messageContent = `<p>${message}</p>`;
      formData.append("content", messageContent); // ارسال محتوای <p> به سرور

      if (file) {
        formData.append("file", file);
      }

      const res = await fetch(
        `https://api.asiamehr.com/api/v1/request/approve/${requestId?._id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const data = await res.json();
      if (data.status||data.success) {
        notify("درخواست با موفقیت تایید شد", "success");
        window.location.reload();
      } else {
        notify("اشکال در تایید درخواست", "error");
      }
    } catch (err) {
      console.log(err);
      notify("خطا در ثبت", "error");
    } finally {
      setFile(null);
      setMessage("");
      setAcceptLoading(false);
    }
  };

  const acceptRef = async (requestId) => {
    try {
      const res = await fetch(
        `https://api.asiamehr.com/api/v1/request/accept/${requestId?._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ referralContent: message }),
        }
      );

      const data = await res.json();
      if (data.success) {
        notify(data?.message, "success");
        window.location.reload();
      } else {
        notify("اشکال در ثبت درخواست", "error");
      }
    } catch (err) {
      console.log(err);
      notify("خطا در ارسال درخواست", "error");
    } finally {
      setMessage("");
    }
  };
  const rejectFunc = async (event, requestId) => {
    event.preventDefault();
    setRejectLoading(true);
    try {
      const formData = new FormData();
      const messageContent = `<p>${message}</p>`;
      formData.append("content", messageContent);
      if (file) {
        formData.append("file", file);
      }
      const res = await fetch(
        `https://api.asiamehr.com/api/v1/request/reject/${requestId?._id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const data = await res.json();
      if (data.status) {
        notify(data?.message, "success");
        window.location.reload();
      } else {
        notify("اشکال در ثبت درخواست", "error");
      }
    } catch (err) {
      console.log(err);
      notify("خطا در ارسال درخواست", "error");
    } finally {
      setFile(null);
      setMessage("");
      setRejectLoading(false);
    }
  };
  const handleReferral = async () => {
    try {
      const res = await fetch(
        `https://api.asiamehr.com/api/v1/request/referral/${requestId?._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            referralId: value?._id,
            referralName: value?.fullName,
          }),
        }
      );

      const data = await res.json();

      // اضافه کردن چک وضعیت موفقیت
      if (data.success) {
        notify("ارجاع با موفقیت انجام شد", "success");
        handleCloseDialog();
        setValue("");
        window.location.reload();
      } else {
        notify("اشکال در ارجاع", "error");
        setValue("");
      }
    } catch (error) {
      notify("خطا در ارجاع", "error");
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  useEffect(() => {
    if (!process) {
      // If process or process.steps is null or undefined, return early
      return;
    }
    let newProcess = [];
    let activeStep = currentStepIndex - 1;
    // Function to handle process steps
    const updateProcess = (e, index) => {
      if (index === currentStepIndex-1) {
        if (currentStep?.referralId) {
          newProcess.push({ ...currentStep, return: true });
          newProcess.push(currentStep);
          activeStep++;
        }
        if (currentStep?.referralContent) {
          activeStep++;
        }
      }
      newProcess.push(e);
      if (steps[index]?.referralId) {
        newProcess.push(steps[index]);
        activeStep++;
      }
      if (steps[index]?.referralContent) {
        newProcess.push({ ...steps[index], return: true });
        activeStep++;
      }
    };
    // Loop through the steps
    if (steps?.length > 0) {
      process.steps.forEach(updateProcess);
      // Handle the current step addition separately
    } else {
      process.steps.forEach((e, index) => {
        newProcess.push(e);
        if (steps[index]?.referralId) {
          newProcess.push(steps[index]);
          activeStep++;
        }
        if (steps[index]?.referralContent) {
          newProcess.push({ ...steps[index], return: true });
          activeStep++;
        }
        if (index === 0) {
          if (currentStep?.referralId) {
            newProcess.push(currentStep);
            newProcess.push({ ...currentStep, return: true });
            activeStep++;
          }
          if (currentStep?.referralContent) {
            activeStep++;
          }
        }
      });
    }
    setNews(newProcess);
    setActiveStep(activeStep);
  }, [process, steps, currentStep, currentStepIndex]);
  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 'calc(50% ,-50%)', // تنظیم خط به مرکز دقیق
    // transform: 'none', // غیرفعال کردن تغییر موقعیت قبلی
    flex: '1', // خطوط متناسب با فضای بین استپ‌ها کشیده شوند
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4', // رنگ خط در حالت فعال
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4', // رنگ خط در حالت کامل
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#eaeaf0', // رنگ خط در حالت غیرفعال
      borderTopWidth: 3, // ضخامت خط
      borderRadius: 1, // گرد کردن گوشه‌های خط
    },
  }));
  return (
    <>
      <TableRow>
      <TableCell align="right" sx={{ gap: 2 }}>
          {pdf ? (
            <a
              target="_blank"
              style={{ color: "black" }}
              href={`https://api.asiamehr.com/uploads/${pdf}`}
              download
            >
              <DownloadIcon />
            </a>
          ) : (
            ""
          )}
          <Link
            style={{ color: "black", margin: "0 10px" }}
            onClick={() => {
              handleOpen();
              getSteps(processId);
            }}
          >
            <TimelineIcon />
          </Link>
        </TableCell>

        <TableCell align="right">{currentIndex + "/" + allStep}</TableCell>

        <TableCell align="right">
          {status === "pending"
            ? "درحال انتظار"
            : status === "failed"
              ? "رد شده"
              : "تایید شده"}
        </TableCell>

        <TableCell align="right">{receiver?.fullName || "----"}</TableCell>

        <TableCell align="right">{requestType} </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {process ? (
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              width: isMobile ? "80%" : 900,
              p: 4,
              borderRadius: 2,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <Stack
              width={"100%"}
              height={"100%"}
              flexDirection={"column"}
              gap={"20px"}
              borderRadius={"20px"}
            >
              <Grid
                container
                py={"10px"}
                px={1}
                justifyContent={"space-between"}
                sx={{
                  borderRadius: "15px",
                  backgroundColor: "#F9F9F9",
                  boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                  direction: "ltr",
                }}
              >
                <Grid
                  p={1}
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    fontSize={{ xs: "14px", xl: "18px" }}
                    variant="h5"
                    sx={{ color: "black", textAlign: "right" }}
                  >
                    نوع درخواست: {requestType}
                  </Typography>
                </Grid>

                <Grid
                  p={1}
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    fontSize={{ xs: "14px", xl: "18px" }}
                    variant="h5"
                    sx={{ color: "black", textAlign: "right" }}
                  >
                    شماره درخواست: {requestId?.requestId}
                  </Typography>
                </Grid>

                <Grid
                  p={1}
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    fontSize={{ xs: "14px", xl: "18px" }}
                    variant="h5"
                    sx={{ color: "black", textAlign: "right" }}
                  >
                    تاریخ: {requestDate}
                  </Typography>
                </Grid>

                <Grid
                  p={1}
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    fontSize={{ xs: "14px", xl: "18px" }}
                    variant="h5"
                    sx={{ color: "black", textAlign: "right" }}
                  >
                    درخواست کننده: {sender?.fullName}
                  </Typography>
                </Grid>

                <Grid
                  p={1}
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    fontSize={{ xs: "14px", xl: "18px" }}
                    variant="h5"
                    sx={{ color: "black", textAlign: "right" }}
                  >
                    وضعیت درخواست :{" "}
                    {status === "pending"
                      ? "درحال انتظار"
                      : status === "failed"
                        ? "رد شده"
                        : "تایید شده"}
                  </Typography>
                </Grid>
                {!isMobile && (
                  <Grid
                    p={1}
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  ></Grid>
                )}
              </Grid>
              <Stack
                py={"20px"}
                px={"5%"}
                lineHeight={"40px"}
                sx={{
                  borderRadius: "15px",
                  fontSize: isMobile ? "12px" : "18px",
                  direction: "ltr",
                  textAlign: "end !important",
                  backgroundColor: "#F9F9F9",
                  boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                  whiteSpace: "pre-wrap",
                }}
                gap={2}
              >
                <Typography
                  minHeight={"300px"}
                  py={"20px"}
                  lineHeight={"40px"}
                  px={"5%"}
                  sx={{
                    borderRadius: "15px",
                    fontSize: isMobile ? "12px" : "18px",
                    direction: "rtl",
                    textAlign: "end !important",
                    backgroundColor: "#fff",
                    // boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                    whiteSpace: "pre-wrap",
                    border: "1px #fff solid",
                  }}
                >
                  {extractContent(content)}
                </Typography>
                {attachment && (
                  <Button
                    variant="contained"
                    sx={{
                      alignSelf: "end",
                      width: {
                        xs: "100%", // برای سایز xs
                        sm: "auto", // برای سایز sm و بالاتر
                      },
                    }}
                    href={`https://api.asiamehr.com/uploads/${attachment}`}
                    download={attachment || "file"}
                  >
                    دانلود پیوست
                  </Button>
                )}
              </Stack>
              <Stack
                py={"20px"}
                px={"5%"}
                lineHeight={"40px"}
                sx={{
                  borderRadius: "15px",
                  fontSize: "18px", // حذف شرط ریسپانسیو برای فونت
                  direction: "ltr",
                  textAlign: "end !important",
                  backgroundColor: "#F9F9F9",
                  boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                  whiteSpace: "nowrap", // جلوگیری از شکستن خطوط
                  overflowX: "auto", // فعال کردن اسکرول افقی
                  minWidth: "400px", // حداقل عرض
                }}
                gap={2}
              >
                <Box
                  sx={{
                    overflowX: "auto", // اسکرول افقی برای استپر
                    whiteSpace: "nowrap", // جلوگیری از شکستن خطوط
                    width: "100%", // عرض کامل
                  }}
                >
                  <Stepper
                    sx={{
                      minWidth: "600px", // حداقل عرض برای استپر
                      paddingBottom: "15px",
                      display: 'flex', // استفاده از flex برای تنظیم خودکار فضاها
                      justifyContent: "space-between", // افزایش فضای بین استپ‌ها
                    }}
                    activeStep={isFainally?activeStep+1:activeStep}
                    alternativeLabel
                    connector={<CustomConnector />} // استفاده از کانکتور سفارشی
                  >
                    {news?.map((label) => (
                      <Step
                        key={
                          label?.return
                            ? label?.fullName
                            : label?.referralName || label?.userId?.fullName
                        }
                        sx={{
                          flex: '1', // استپ‌ها با توجه به متن فضای بیشتری می‌گیرند
                          padding: '0 16px', // فاصله اضافی برای متن‌های طولانی‌تر
                          minWidth: '150px', // حداقل عرض برای هر استپ
                        }}
                      >
                        <StepLabel sx={{ textAlign: 'center', fontSize: '12px' }}>
                          {label?.return
                            ? label?.fullName
                            : label?.referralName || label?.userId?.fullName}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                >
                  {steps[currentStepIndex - 1]?.status === "failed" ||
                    currentStep == null ? (
                    <>
                      <Typography
                        width={"100%"}
                        minHeight={"50px"}
                        py={"20px"}
                        lineHeight={"40px"}
                        px={"5%"}
                        sx={{
                          borderRadius: "15px",
                          fontSize: "18px",
                          direction: "rtl",
                          backgroundColor: "#fff",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        درخواست به پایان رسیده است
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setOpenRequestDetails(true)}
                        sx={{ alignSelf: "end" }}
                      >
                        مشاهده جزئیات مراحل
                      </Button>
                    </>
                  ) : (id === receiver?._id && !currentStep?.referralId) ||
                    (id === receiver?._id && currentStep?.referralId === id) ||
                    (id === receiver?._id && currentStep?.referralContent) ? (
                    <>
                      <TextField
                        multiline
                        rows={5}
                        fullWidth
                        placeholder="نظر خود را وارد کنید"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        sx={{
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setOpenRequestDetails(true)}
                        >
                          مشاهده جزئیات مراحل
                        </Button>
                        {currentStep?.status !== "referral" &&
                          (!file ? (
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                backgroundColor: "#1976d2",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#1565c0",
                                },
                              }}
                            >
                              انتخاب فایل پیوست
                              <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                              />
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                backgroundColor: "red",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#d32f2f",
                                },
                              }}
                              onClick={() => setFile(null)}
                            >
                              حذف پیوست
                            </Button>
                          ))}

                        {(currentStep?.referralId !== id && (!currentStep?.referralId || !currentStep?.referralContent)) && (
                          <Button
                            variant="contained"
                            startIcon={<ReplayIcon />}
                            sx={{
                              direction: "ltr",
                              "&.MuiLoadingButton-loading": {
                                bgcolor: "primary.main",
                              },
                            }}
                            color="primary"
                            onClick={handleOpenDialog}
                          >
                            ارجاع
                          </Button>
                        )}

                        {currentStep?.status === "referral" ? (
                          <LoadingButton
                            variant="contained"
                            startIcon={<SendIcon />}
                            sx={{
                              direction: "ltr",
                            }}
                            onClick={(event) => {
                              acceptRef(requestId);
                              handleClose();
                            }}
                          >
                            ارسال
                          </LoadingButton>
                        ) : (
                          <>
                            <LoadingButton
                              loading={rejectLoading}
                              variant="contained"
                              startIcon={<CancelIcon />}
                              sx={{
                                direction: "ltr",
                              }}
                              onClick={(event) => {
                                rejectFunc(event, requestId);
                                handleClose();
                              }}
                              color="error"
                            >
                              عدم تایید
                            </LoadingButton>
                            <LoadingButton
                              loading={acceptLoading}
                              variant="contained"
                              startIcon={<CheckCircleIcon />}
                              sx={{
                                direction: "ltr",
                                "&.MuiLoadingButton-loading": {
                                  bgcolor: "primary.main",
                                },
                              }}
                              color="success"
                              onClick={(event) => {
                                acceptFunc(event, requestId);
                                handleClose();
                              }}
                            >
                              تایید
                            </LoadingButton>
                          </>
                        )}
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Typography
                        width={"100%"}
                        py={"20px"}
                        lineHeight={"40px"}
                        px={"5%"}
                        sx={{
                          borderRadius: "15px",
                          fontSize: "18px",
                          direction: "rtl",
                          backgroundColor: "#fff",
                          whiteSpace: "pre-wrap",
                        }}
                        textAlign={"center"}
                      >
                        در انتظار تایید
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setOpenRequestDetails(true)}
                        sx={{ alignSelf: "end" }}
                      >
                        مشاهده جزئیات مراحل
                      </Button>
                    </>
                  )}
                </Stack>

                <Modal
                  open={openRequestDetails}
                  onClose={() => setOpenRequestDetails(false)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      width: isMobile ? "80%" : 900,
                      p: 4,
                      borderRadius: 2,
                      maxHeight: "600px",
                      overflow: "auto",
                    }}
                  >
                    <Typography mb={2} fontSize={"22px"}>
                      جزئیات مراحل
                    </Typography>
                    <Divider />
                    {steps?.map((e, index) => {
                      return (
                        <>
                          <Stack gap={2} my={2}>
                            {e.referralId && (
                              <>
                                <Stack gap={2} my={2}>
                                  <Stack
                                    sx={{
                                      flexDirection: {
                                        sm: "row-reverse",
                                        xs: "column",
                                      },
                                      gap: { sm: 0, xs: 3 },
                                    }}
                                  >
                                    <Typography flex={0.5}>
                                      {stepNumber++}
                                    </Typography>{" "}
                                    {/* مرحله اول */}
                                    <Typography
                                      flex={1}
                                      textAlign={"left !important"}
                                    >
                                      {e.fullName}
                                    </Typography>
                                    <Typography
                                      flex={2}
                                      textAlign={"left !important"}
                                      p={1}
                                      sx={{
                                        minHeight: "300px",
                                        border: "1px solid #bababa",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      ارجاع داده شده به {e.referralName}
                                    </Typography>
                                  </Stack>
                                </Stack>
                                <Divider />

                                {e.referralContent && (
                                  <>
                                    <Stack gap={2} my={2}>
                                      <Stack
                                        sx={{
                                          flexDirection: {
                                            sm: "row-reverse",
                                            xs: "column",
                                          },
                                          gap: { sm: 0, xs: 3 },
                                        }}
                                      >
                                        <Typography flex={0.5}>
                                          {stepNumber++}
                                        </Typography>{" "}
                                        {/* مرحله بعدی */}
                                        <Typography
                                          flex={1}
                                          textAlign={"left !important"}
                                        >
                                          : نظر ارجاعی {e.referralName}
                                        </Typography>
                                        <Typography
                                          flex={2}
                                          textAlign={"left !important"}
                                          p={1}
                                          sx={{
                                            minHeight: "300px",
                                            border: "1px solid #bababa",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {e.referralContent}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                    <Divider />
                                  </>
                                )}
                              </>
                            )}

                            <Stack
                              sx={{
                                flexDirection: {
                                  sm: "row-reverse",
                                  xs: "column",
                                },
                                gap: { sm: 0, xs: 3 },
                              }}
                            >
                              <Typography
                                flex={0.5}
                                sx={{ display: { sm: "block", xs: "none" } }}
                              >
                                {stepNumber++} {/* مرحله جاری */}
                              </Typography>

                              <Typography
                                flex={1}
                                textAlign={"left !important"}
                              >
                                {e.fullName}
                              </Typography>
                              <Typography
                                flex={2}
                                textAlign={"left !important"}
                                p={1}
                                sx={{
                                  minHeight: "300px",
                                  border: "1px solid #bababa",
                                  borderRadius: "8px",
                                }}
                              >
                                {extractContent(e.content)}
                              </Typography>
                            </Stack>

                            {e?.attachment && (
                              <Stack
                                flexDirection={"row"}
                                sx={{
                                  alignSelf: { sm: "start", xs: "normal" },
                                }}
                              >
                                <Button
                                  variant="contained"
                                  href={`https://api.asiamehr.com/uploads/${e?.attachment}`}
                                  fullWidth
                                >
                                  دانلود پیوست
                                </Button>
                              </Stack>
                            )}
                          </Stack>
                          <Divider />
                        </>
                      );
                    })}
                    {currentStep?.referralId && (
                      <>
                        <Stack gap={2} my={2}>
                          <Stack
                            sx={{
                              flexDirection: {
                                sm: "row-reverse",
                                xs: "column",
                              },
                              gap: { sm: 0, xs: 3 },
                            }}
                          >
                            <Typography flex={0.5}>{stepNumber++}</Typography>
                            <Typography flex={1} textAlign={"left !important"}>
                              {currentStep.fullName}
                            </Typography>
                            <Typography
                              flex={2}
                              textAlign={"left !important"}
                              p={1}
                              sx={{
                                minHeight: "300px",
                                border: "1px solid #bababa",
                                borderRadius: "8px",
                              }}
                            >
                              ارجاع داده شده به {currentStep?.referralName}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />

                        {currentStep?.referralContent && (
                          <>
                            <Stack gap={2} my={2}>
                              <Stack
                                sx={{
                                  flexDirection: {
                                    sm: "row-reverse",
                                    xs: "column",
                                  },
                                  gap: { sm: 0, xs: 3 },
                                }}
                              >
                                <Typography flex={0.5}>
                                  {stepNumber++}
                                </Typography>
                                <Typography
                                  flex={1}
                                  textAlign={"left !important"}
                                >
                                  : نظر ارجاعی {currentStep?.referralName}
                                </Typography>
                                <Typography
                                  flex={2}
                                  textAlign={"left !important"}
                                  p={1}
                                  sx={{
                                    minHeight: "300px",
                                    border: "1px solid #bababa",
                                    borderRadius: "8px",
                                  }}
                                >
                                  {currentStep?.referralContent}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Divider />
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Modal>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal
        open={openF}
        onClose={handleCloseF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: isMobile ? "80%" : 800,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Stack
            width={"100%"}
            height={"100%"}
            flexDirection={"column"}
            gap={"20px"}
            borderRadius={"20px"}
          >
            <Stack
              flexDirection={"row-reverse"}
              flexWrap={"wrap"}
              p={2}
              gap={4}
              justifyContent={"end"}
              sx={{
                borderRadius: "15px",
                backgroundColor: "#F9F9F9",
                boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
              }}
            >
              <Typography
                fontSize={isMobile ? "14px" : "18px"}
                variant="h6"
                sx={{ color: "black" }}
              >
                نوع نامه : {requestType}
              </Typography>
              <Typography
                fontSize={isMobile ? "14px" : "18px"}
                variant="h6"
                sx={{ color: "black" }}
              >
                گیرنده : {receiver?.fullName}
              </Typography>
            </Stack>
            <Typography
              py={"20px"}
              lineHeight={"40px"}
              px={"5%"}
              sx={{
                borderRadius: "15px",
                fontSize: isMobile ? "12px" : "18px",
                direction: "rtl",
                textAlign: "end !important",
                backgroundColor: "#F9F9F9",
                boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
              }}
            >
              {content}
            </Typography>
          </Stack>
        </Box>
      </Modal>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "15px",
            padding: "10px 5px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#1976d2",
            paddingBottom: "16px",
          }}
        >
          انتخاب گیرنده ارجاع
        </DialogTitle>

        <DialogContent>
          <Stack
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {/* اتوکامپلیت */}
            <Autocomplete
              size="small"
              options={options}
              getOptionLabel={(option) => option.fullName}
              value={value}
              onChange={handleChange}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="گیرنده"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    direction: "rtl",
                    textAlign: "right",
                    padding: "8px 16px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {option?.fullName} - {option?.employeePosition}
                </li>
              )}
              ListboxProps={{
                style: {
                  maxHeight: "350px",
                  overflowY: "auto",
                },
              }}
              sx={{
                width: "100%",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                "& input": {
                  textAlign: "right",
                  fontSize: "16px",
                },
                boxShadow: "0 0 10px 2px rgba(0,0,0,0.1)",
                "& .MuiAutocomplete-listbox": {
                  padding: "10px",
                  fontSize: "14px",
                  boxShadow: "none",
                  backgroundColor: "#fff",
                },
              }}
            />
          </Stack>
        </DialogContent>

        {/* دکمه‌های پایین دیالوگ */}
        <DialogActions
          sx={{
            justifyContent: "start",
            padding: "16px 24px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleReferral(index);
              handleCloseDialog();
            }}
            disabled={!value}
            sx={{
              padding: "6px 18px",
              borderRadius: "8px",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
          >
            ارسال
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default function AllRequests() {
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [requests, setRequests] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:900px)");
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await fetch(
          `https://api.asiamehr.com/api/v1/request?populate=processId,receiverStep,sender&sort=${sort}&page=${page}&limit=${10}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.succuss) {
          setRequests(data?.data?.request);
          setCount(Math.ceil(data.count / 10));
        } else {
          alert("اشکال در برقراری اتصال");
        }
      } catch (err) {
        alert("اشکال در برقراری اتصال");
      } finally {
        setLoading(false);
      }
    })();
  }, [token, sort, page]);
  const items = requests.map((e, index) => (
    <TableItems
      index={index}
      allSteps={e?.processId?.stepCount}
      content={e?.content}
      currentStepIndex={e?.currentStepIndex + 1}
      currentStep={e?.currentStep}
      receiver={e?.receiverStep}
      requestType={e?.processId?.name}
      status={e?.status}
      key={index}
      processId={e?.processId?._id}
      requestId={e}
      requestDate={e?.requestDate}
      sender={e?.sender}
      steps={e?.steps}
      attachment={e?.attachment}
      pdf={e?.pdf}
    />
  ));

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              overflowX: "scroll",
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0,
            }}
          >
            {loading ? ( // نمایش لودر هنگام بارگیری داده‌ها
              <Loader />
            ) : (
              <>
                <CacheProvider value={cacheRtl}>
                  <Stack
                    flexDirection={"row"}
                    pt={"20px"}
                    sx={{ direction: "rtl !important" }}
                  >
                    <Stack
                      dir="rtl"
                      width={"100%"}
                      justifyContent={"flex-end"}
                      flexDirection={isMobile ? "column" : "row"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        onChange={(e) => setSort(e.target.value)}
                        placeholder="مرتب سازی بر اساس"
                        sx={{
                          width: isMobile ? "100%" : "270px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                        defaultValue={"s"}
                      >
                        <MenuItem dir="rtl" value="s">
                          مرتب سازی بر اساس
                        </MenuItem>
                        <MenuItem dir="rtl" value="-createdAt">
                          جدید ترین نامه ها
                        </MenuItem>
                        <MenuItem dir="rtl" value="createdAt">
                          قدیمی ترین نامه ها
                        </MenuItem>
                      </TextField>
                    </Stack>
                  </Stack>

                  <TableContainer
                    sx={{
                      marginTop: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      overflowX: "auto",
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        direction: "rtl",
                        minWidth: "700px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            جزئیات
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            مرحله
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            وضعیت فعلی
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            گیرنده مرحله
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            نوع درخواست
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items && items.length > 0 ? (
                          items
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={5}
                              align="center"
                              sx={{
                                padding: "20px",
                                fontStyle: "italic",
                                color: "#888",
                              }}
                            >
                              درخواستی موجود نیست
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    siblingCount={0}
                    boundaryCount={0}
                    defaultPage={1}
                    count={count}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    color="primary"
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                      display: "flex",
                      "& .MuiPaginationItem-root": {
                        bgcolor: "white",
                        borderRadius: 2,
                        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.2)",
                        "&:hover": {
                          bgcolor: "grey.200",
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      },
                    }}
                  />
                </CacheProvider>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
