import React, { useState, useEffect ,useContext} from 'react';
import { Box, Button, Stack, Typography, Snackbar, Alert } from '@mui/material';
import PanelSidebar from '../PanelSlidebar';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../utils/AuthContext';
export default function DeleteUser() {
  const { loginUser } = useContext(AuthContext)
  const [users, setUsers] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    severity: 'error'
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/user', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        const data = await response.json();
        console.log('Fetched users:', data.users);
        setUsers(data.users || []);
      } catch (error) {
        console.error('Error fetching users:', error);
        setSnack({ open: true, message: 'خطا در بارگیری کاربران', severity: 'error' });
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://api.asiamehr.com/api/v1/user/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.ok) {
        console.log('User deleted:', id);
        setSnack({ open: true, message: 'کاربر با موفقیت حذف شد', severity: 'success' });
        setUsers(users.filter(user => user._id !== id));
      } else {
        throw new Error('Error deleting user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnack({ open: true, message: 'خطا در حذف کاربر', severity: 'error' });
    }
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div>
    <PanelSidebar/>
    <Stack alignItems="center" justifyContent="center" height="100vh">
      <Box width="90%" maxWidth="600px" p={3} bgcolor="background.paper" borderRadius={1}>
        <Typography component="h1" variant="h5" align="center" gutterBottom>مدیریت کاربران</Typography>
        {users.map(user => (
          <Box key={user._id} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography>{user.fullName} ({user.email})</Typography>
            <Button variant="contained" color="secondary" onClick={() => handleDelete(user._id)}>حذف</Button>
          </Box>
        ))}
      </Box>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert onClose={() => setSnack({ ...snack, open: false })} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
    </Stack>
   </div>
  );
}
