import React from 'react'
import ApartmentIcon from '@mui/icons-material/Apartment';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import cart from './cart.css'
import { color, makeStyles, rgbToHex } from '@mui/material';
import { brown, yellow } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import EngineeringIcon from '@mui/icons-material/Engineering';
function Cart() {
  return (
    <div className='cart'>
       {/* <div className='cart-image' > <img src={home} alt="" />  </div> */}
       <div className='carts' >
       <div  className="box">
        <div className='logo'><HandymanOutlinedIcon  sx={{fontSize:70,color:'rgb(189,155,25)'
   } } /></div>
        {/* <div className='text'> <h1> توضیحات </h1> </div> */}
        {/* <div className='subtext'> <Link to={'/'} > بازدید </Link> </div> */}
       </div>
       <div  className="box">
        <div className='logo'><RoomPreferencesOutlinedIcon  sx={{fontSize:70,color:'rgb(189,155,25)'
   } } /></div>
        {/* <div className='text'> <h1> توضیحات </h1></div> */}
        {/* <div className='subtext'><Link to={'/'} > بازدید </Link> </div> */}
       </div>
       <div  className="box">
        <div className='logo'><EngineeringIcon  sx={{fontSize:70,color:'rgb(189,155,25)'
   } } /></div>
        {/* <div className='text'> <h1> توضیحات </h1></div> */}
        {/* <div className='subtext'><Link to={'/'} > بازدید </Link> </div> */}
       </div>
       <div  className="box">
        <div className='logo'><ApartmentIcon  sx={{fontSize:70,color:'rgb(189,155,25)'
   } } /></div>
         {/* <div className='text'> <h1> توضیحات </h1></div> */}
        {/* <div className='subtext'> <Link to={'/'} > بازدید </Link> </div> */}
       </div>
       </div> 
      
    </div>
  )
}

export default Cart
