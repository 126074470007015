import React, { useEffect, useState } from 'react';
import './gallery.css';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import Nav from '../../Components/Nav';
import GalleryInfo from './galleryInfo';

function Gallery() {
  const [t] = useTranslation();
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/galleryy');
        const data = await response.json();
        if (response.ok) {
          setGalleryItems(data.data); // Adjust according to your API response structure
        } else {
          console.error('Failed to fetch gallery items:', data);
        }
      } catch (error) {
        console.error('Error fetching gallery items:', error);
      }
    };

    fetchGalleryItems();
  }, []);

  return (
    <div>
      <Nav />
      {/* <div className="responsive-container-block bigContainer"> */}
        {/* <div className="responsive-container-block Container">
          <div className="responsive-container-block leftSide">
            
          </div>
          <div className="responsive-container-block rightSide">
            
            {galleryItems.map((item, index) => (
              <img key={index} className={`number${index + 1}img`} src={`http://localhost:3001${item.image}`} alt={`Gallery item ${index + 1}`} />
            ))}
               
            <iframe
              className="number4vid"
              title={t('contact.map_title')}
              width="100%"
              height="450"
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3229.6604267017588!2d59.334701!3d35.955276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDU3JzE5LjAiTiA1OcKwMjAnMDQuOSJF!5e0!3m2!1sen!2s!4v1712749110672!5m2!1sen!2s"
              style={{ border: 'none', borderRadius: '4px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
            />
          </div>
        </div>
        <GalleryInfo/>
      </div> */}
      <div className='gallery-text'>
      <p className="text-title">{t('text.heading')}</p>
      <p className="text-sub">{t('text.subHeading')}</p>
      </div>
      <GalleryInfo/>
     
    </div>
  );
}

export default Gallery;
