import  Slider  from '../../Components/Slider'
import React from 'react'
import Cart from '../Cart/cart'
import Main from '../Main/Main'
import Footer from '../Footer/Footer'
import MainCart from '../Main/MainCart'
import MainGallery from '../Main/MainGallery'

import Nav from '../../Components/Nav'
import Carrousel from '../Main/Carrousel'
import ContactForm from '../Main/Cform'

function Home() {
  return (
    <div>
   <Nav/>
      <Slider/>
     <Cart/>
     
  
      <Main/>
      <MainCart/>
    
      <MainGallery/>
      <Carrousel/>
   <Footer/>
    </div>
  )
}

export default Home
