import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  Typography,
  useMediaQuery,
  Stack,
  Input,
  Link,
  Button,
  Modal,
  Fade,
  Autocomplete,
  CircularProgress,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import PanelSidebar from "../../../Components/Pane/PanelSlidebar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { useParams } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { AuthContext } from "../../../utils/AuthContext";
import Loader from "../../../Components/Loader";
import notify from "../../../utils/hotToast";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function GetOneInternalLetter() {
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const { id } = useParams();
  const [letter, setLetter] = useState(null);
  const [recipient, setRecipient] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [transcriptText, setTranscriptText] = useState("");
  const [transcriptOpen, setTranscriptOpen] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [createTranscriptLoading, setCreateTranscriptLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:900px)");

  const handleTranscriptOpen = () => setTranscriptOpen(true);
  const handleTranscriptClose = () => setTranscriptOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function getUserTranscript(id) {
    fetch(
      `https://api.asiamehr.com/api/v1/transcript/${id}?type=internalLetter`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())

      .then((data) => {
        console.log(data);
        if (data.success) {
          setTranscripts(data.data);
        } else {
          notify("خطا در دریافت رونوشت‌ها", "error");
        }
      });
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          `https://api.asiamehr.com/api/v1/internalLetter/${id}?populate=sender`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.success) {
          setLetter(data?.data);
        } else {
          alert("اشکال در برقراری اتصال");
        }
      } catch (err) {
        alert("اشکال در برقراری اتصال");
      }
    })();
  }, [token, id]);

  const createTranscript = () => {
    setCreateTranscriptLoading(true)
    fetch("https://api.asiamehr.com/api/v1/transcript", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        letterId: id,
        description: transcriptText,
        letterType: "InternalLetter",
        receiverId: recipient,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          notify(data.message, "success");
        } else {
          notify("خطا در ارسال رونوشت", "error");
        }
        setCreateTranscriptLoading(false)
        handleTranscriptClose();
        setRecipient("");
        setSearchTerm("");
      });
  };

  useEffect(() => {
    setLoading(true);
    fetch(`https://api.asiamehr.com/api/v1/user-letter?search=${searchTerm}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOptions(data?.data?.users || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [searchTerm, token]);

  const extractContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
  
    let result = tempDiv.textContent || tempDiv.innerText || "";
  
    return result.trim();
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0,
            }}
          >
            {letter ? (
              <>
                <Stack
                  mx={"auto"}
                  width={"80%"}
                  flexDirection={"column"}
                  p={"20px"}
                  sx={{ direction: "rtl !important" }}
                  gap={"20px"}
                  mt={"50px"}
                  borderRadius={"20px"}
                  pb={"50px"}
                >
                  <Grid
                    container
                    py={"10px"}
                    px={1}
                    justifyContent={"space-between"}
                    sx={{
                      borderRadius: "15px",
                      backgroundColor: "#F9F9F9",
                      boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                    }}
                  >
                    <Grid
                      p={1}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        fontSize={{ xs: "14px", xl: "18px" }}
                        variant="h5"
                        sx={{ color: "black", textAlign: "right" }}
                      >
                        موضوع: {letter?.subject}
                      </Typography>
                    </Grid>
                    <Grid
                      p={1}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        fontSize={{ xs: "14px", xl: "18px" }}
                        variant="h5"
                        sx={{ color: "black", textAlign: "right" }}
                      >
                        شماره نامه: {letter?.letterId}
                      </Typography>
                    </Grid>
                    <Grid
                      p={1}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        fontSize={{ xs: "14px", xl: "18px" }}
                        variant="h5"
                        sx={{ color: "black", textAlign: "right" }}
                      >
                        تاریخ: {letter?.letterDate}
                      </Typography>
                    </Grid>
                    <Grid
                      p={1}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        fontSize={{ xs: "14px", xl: "18px" }}
                        variant="h5"
                        sx={{ color: "black", textAlign: "right" }}
                      >
                        فرستنده: {letter?.sender?.fullName}
                      </Typography>
                    </Grid>
                    <Grid
                      p={1}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        fontSize={{ xs: "14px", xl: "18px" }}
                        variant="h5"
                        sx={{ color: "black", textAlign: "right" }}
                      >
                        گیرنده: {letter?.receiver?.fullName}
                      </Typography>
                    </Grid>
                    <Grid
                      p={1}
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{
                        display: { xs: "none", sm: "block" },
                        justifyContent: "flex-start",
                      }}
                    ></Grid>
                  </Grid>

                  <Stack
                    sx={{
                      borderRadius: "15px",
                      direction: "rtl",
                      backgroundColor: "#F9F9F9",
                      boxShadow: "0 4px 4px 0 rgba(0,0,0,.25)",
                      whiteSpace: "pre-wrap",
                      minHeight: "500px",
                    }}
                    py={"20px"}
                    px={"5%"}
                    gap={2}
                  >
                    <Typography
                      textAlign={"right !important"}
                      fontSize={{ xs: "14px", xl: "18px" }}
                    >
                      متن نامه :
                    </Typography>
                    <Typography
                      textAlign={"right !important"}
                      lineHeight={"40px"}
                      fontSize={{ xs: "14px", xl: "18px" }}
                    >
                      {letter?.content && extractContent(letter?.content)}
                    </Typography>
                  </Stack>
                  <Stack flexDirection={"row-reverse"} gap={2}>
                    {letter.attachment && (
                      <Button variant="contained" sx={{ alignSelf: "end" }}>
                        <a
                          download={letter?.attachment}
                          href={
                            "https://api.asiamehr.com/uploads/" +
                            letter?.attachment
                          }
                          style={{ color: "white" }}
                        >
                          دانلودپیوست
                        </a>
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      sx={{ alignSelf: "end" }}
                      onClick={handleTranscriptOpen}
                    >
                      ثبت رو نوشت
                    </Button>
                  </Stack>
                </Stack>
              </>
            ) : (
              <Loader />
            )}
          </Stack>
        </Stack>
      </Box>

      {/* مودال ثبت رونوشت */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={transcriptOpen}
        onClose={handleTranscriptClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{ border: "1px solid #ccc" }}
      >
        <Fade in={transcriptOpen}>
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { md: "500px", sm: "70%", xs: "80%" },
              bgcolor: "background.paper",
              border: "1px solid #ccc",
              boxShadow: 24,
              p: 2,
              borderRadius: "16px",
            }}
            gap={2}
          >
            <Autocomplete
              size="small"
              options={options}
              getOptionLabel={(option) => option.fullName}
              loading={loading}
              onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
              }}
              onChange={(event, newValue) => {
                setRecipient(newValue ? newValue._id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="گیرنده"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ direction: "rtl", textAlign: "right" }}>
                  {option?.fullName} - {option?.employeePosition}
                </li>
              )}
              ListboxProps={{
                style: {
                  maxHeight: "350px",
                  overflowY: "auto",
                },
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                "& input": {
                  textAlign: "right !important",
                  fontSize: "16px",
                },
                textAlign: "right !important",
                direction: "rtl",
                border: "none",
                boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                "& .MuiAutocomplete-listbox": {
                  maxHeight: "350px",
                  overflowY: "auto",
                },
              }}
            />
            <Typography id="transition-modal-title" variant="h6" component="h2">
              : متن رونوشت
            </Typography>
            <TextField
              multiline
              rows={5}
              fullWidth
              onChange={(e) => setTranscriptText(e.target.value)}
            />
            <Stack flexDirection={{ sm: "row", xs: "column" }} gap={2}>
              <Stack flexDirection={"row"} gap={2}>
                <LoadingButton
                  loading={createTranscriptLoading}
                  variant="contained"
                  onClick={createTranscript}
                  sx={{
                    "&.MuiLoadingButton-loading": {
                      bgcolor: "primary.main", // رنگ دکمه در حالت لودینگ
                    },
                  }}
                  color="success"
                >
                  تایید
                </LoadingButton>

                <Button
                  variant="contained"
                  onClick={handleTranscriptClose}
                  sx={{
                    alignSelf: "end",
                    width: {
                      xs: "100%", // برای سایز xs
                      sm: "auto", // برای سایز sm و بالاتر
                    },
                  }}
                >
                  بازگشت
                </Button>
              </Stack>
              <Stack>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#9D9D9D",
                    "&:hover": { backgroundColor: "#9D9D9D" },
                  }}
                  onClick={() => {
                    handleOpen();
                    getUserTranscript(id);
                  }}
                >
                  مشاهده همه رونوشت ها
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Fade>
      </Modal>

      {/* مودال مشاهده همه رونوشت‌ها */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Stack
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "1px solid #ccc",
              boxShadow: 24,
              p: 2,
              borderRadius: "16px",
            }}
            gap={2}
          >
            <Tooltip title="بستن">
              <IconButton
                sx={{ width: "30px", height: "30px", color: "black" }}
                variant="contained"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <TableContainer
              component={Paper}
              sx={{
                border: "1px solid #ccc",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Added box shadow
                borderRadius: "8px", // Optional: rounded corners for the table container
                maxHeight: "400px",
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  direction: "rtl",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                    <TableCell
                      align="center"
                      sx={{ width: "5%", fontWeight: "bold" }}
                    ></TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      گیرنده
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      متن رونوشت
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transcripts && transcripts.length > 0 ? (
                    transcripts.map((transcript, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          "&:hover": {
                            backgroundColor: "inherit",
                          },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {transcript.receiverId?.fullName +
                            " - " +
                            transcript?.receiverId?.employeePosition}
                        </TableCell>
                        <TableCell align="right">
                          {transcript.description}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        هیچ رونوشت‌ی وجود ندارد
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              sx={{ width: "100px" }}
              variant="contained"
              onClick={handleClose}
            >
              بازگشت
            </Button>
          </Stack>
        </Fade>
      </Modal>
    </>
  );
}
