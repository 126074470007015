import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './update.css'
import { Navigate } from 'react-router-dom';

import { useContext } from 'react';
import PanelSidebar from '../PanelSlidebar';
import { AuthContext } from '../../../utils/AuthContext';
function CartChange() { // Change the function name to CartChange
  const [cartData, setCartData] = useState([]); // Change sliderData to cartData
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext)
  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/cart`) // Change slider to cart
      .then((res) => res.json())
      .then((data) => setCartData(data.data)) // Change sliderData to cartData
      .catch((err) => console.log(err));
  }, [id]);

  const deleteItem = (id) => {
    fetch(`https://api.asiamehr.com/api/v1/cart/${id}`, { 
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setCartData(cartData.filter(cart => cart._id !== id)); 
      })
      .catch((err) => console.log(err));
      
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div  className='myslider-panel' > {/* Change myslider-panel to mycart-panel */}
        <h1> حذف کردن اطلاعات </h1>
        <PanelSidebar/>
      {cartData?.map(cart => ( // Change sliderData to cartData
        <div className='cart-item'  key={cart._id}> {/* Change slide to cart-item */}
          <h3>{cart.title}</h3>
          <img src={`https://api.asiamehr.com${cart.image}`} alt={cart.title} />
          <p>{cart.description}</p>
          <button onClick={() => deleteItem(cart._id)}>حذف</button>
        </div>
      ))}
    </div>
  );
}

export default CartChange;
