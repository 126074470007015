import React, { useState, useEffect,useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AuthContext } from '../../utils/AuthContext';
import { Navigate } from 'react-router-dom';

const AdminPermissions = () => {
  const [admins, setAdmins] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success'); // Default to success
  const { loginUser } = useContext(AuthContext);

  useEffect(() => {
    // Fetch list of admins from backend API
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {

    try {
      const res = await fetch('https://api.asiamehr.com/api/v1/:Id/permissions', {
        headers: {
          Authorization: `Bearer ${loginUser.token}`,
        },
      });
      if (!res.ok) {
        throw new Error('Failed to fetch admins');
      }
      const data = await res.json();
      setAdmins(data.admins);
    } catch (error) {
      console.error('Error fetching admins:', error);
      setAlertMessage('Error fetching admins');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handlePermissionChange = async (adminId, newPermission) => {
    try {
      const res = await fetch(`https://api.asiamehr.com/api/v1/${adminId}/permissions`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'Application/json',
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: JSON.stringify({ role: newPermission }),
      });
      if (!res.ok) {
        throw new Error('Failed to update permissions');
      }
      const updatedAdmin = await res.json();
      // Update admins state to reflect the change
      setAdmins(admins.map(admin => admin._id === adminId ? updatedAdmin : admin));
      setAlertMessage('Permissions updated successfully');
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      console.error('Error updating permissions:', error);
      setAlertMessage('Error updating permissions');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div className="admin-permissions">
      <h1>Admin Permissions</h1>
      {admins.map(admin => (
        <div key={admin._id}>
          <h2>{admin.fullName}</h2>
          <p>Email: {admin.email}</p>
          <p>Current Role: {admin.role}</p>
          {loginUser.role === 'superAdmin' && (
            <div>
              <label htmlFor={`permissions-${admin._id}`}>Change Role:</label>
              <select id={`permissions-${admin._id}`} onChange={(e) => handlePermissionChange(admin._id, e.target.value)}>
                <option value="admin">Admin</option>
                <option value="superAdmin">Super Admin</option>
                <option value="employee">Employee</option>
              </select>
            </div>
          )}
        </div>
      ))}
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <MuiAlert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AdminPermissions;
