

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Nav from '../../Components/Nav';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import './Project.css';

const Project = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/project');
        const data = await response.json();
        if (response.ok) {
          setProjects(data.data);
        } else {
          console.error('Failed to fetch project data', data);
        }
      } catch (error) {
        console.error('Error fetching project data', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div>
      <Nav />
      <br />
      <div className='all-card'>
        <div className='ptitle'>
          <h1>{t('project.title')}</h1>
        </div>
        {projects.map((project) => (
          <div className="card" key={project.id}>
            <img className="card-image" src={`https://api.asiamehr.com${project.image}`} alt={project.title} />
            <div className="card-content">
              <h2 className="card-title">{project.title}</h2>
              <p className="card-description">{project.description}</p>
            </div>
          </div>
        ))}
        
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Project;
