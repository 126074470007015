import React from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { faIR } from '@mui/material/locale';
import './Main.css'
import { orange } from '@mui/material/colors';

const theme = createTheme({
    palette: {
      primary: orange,
    },
  });
const ContactForm = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="contact_section-form layout_padding">
       
        <div className="container">
          <div className="mail_section">
          <h1>  تماس با ما</h1>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="نام"
                  variant="outlined"
                  placeholder="نام"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="ایمیل"
                  variant="outlined"
                  placeholder="ایمیل"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="شماره تلفن"
                  variant="outlined"
                  placeholder="شماره تلفن"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="پیام"
                  variant="outlined"
                  placeholder="پیام"
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" >ارسال</Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ContactForm;
