import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PanelSidebar from "../../../Components/Pane/PanelSlidebar";
import { AuthContext } from "../../../utils/AuthContext";
import LoadingButton from "@mui/lab/LoadingButton";
import notify from "../../../utils/hotToast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Components/Loader";

export default function GetOneProcess() {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [activeStep, setActiveStep] = useState(0);
  const [processName, setProcessName] = useState("");
  const [processNumber, setProcessNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [options, setOptions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  
  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/process/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setProcessName(data?.data?.process[0]?.name);
          setProcessNumber(data?.data?.process[0]?.stepCount);
          setUsers(data?.data?.process[0]?.steps);
        }
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    fetch(`https://api.asiamehr.com/api/v1/user-letter`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOptions(data?.data?.users || []);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [token]);

  const handleUserSelect = (index, newValue) => {

    const updatedUsers = [...users];
    updatedUsers[index] = {
      stepNumber: index + 1,
      userId: newValue?.userId || null,
    };

    setUsers(updatedUsers);
  };

useEffect(() => {
  setUsers((prevUsers) => {
    if (processNumber > prevUsers.length) {
      const itemsToAddCount = processNumber - prevUsers.length;
      const newItems = Array.from({ length: itemsToAddCount }, () => ({
        stepNumber: null,
        userId: null,
      }));
      return [...prevUsers, ...newItems];
    }
    else if (processNumber < prevUsers.length) {
      return prevUsers.slice(0, processNumber);
    }
    return prevUsers;
  });
}, [processNumber]);


  const allUsersSelected = () => {
    return users.every((user) => user.userId !== null);
  };

  const handleSubmit = async (event) => {
    setBtnLoading(true);
    fetch(`https://api.asiamehr.com/api/v1/process/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: processName,
        stepCount: +processNumber,
        steps: users,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          notify("فرایند با موفقیت به روزرسانی شد", "success");
          navigate("/panel/process/update");
        } else {
          notify("خطا در به روزرسانی فرایند", "error");
        }
      });
    setBtnLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
        </Stack>
        <Stack
          sx={{
            justifyContent: "center",
            width: isMobile ? "100%" : "80%",
            minHeight: "100vh",
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0,
          }}
        >
          {processName ? (
            <Box
              dir="rtl"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: { md: "70%", xs: "90%" },
                borderRadius: "20px",
                margin: "50px auto",
                backgroundColor: "white",
                alignItems: "center",
                maxHeight: "550px",
                overflowY: "auto",
              }}
              p={2}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ direction: "ltr", width: "100%" }}
              >
                <Step>
                  <StepLabel>اطلاعات فرآیند</StepLabel>
                </Step>
                <Step>
                  <StepLabel>مراحل فرآیند</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 ? (
                <Stack gap={2} width={"70%"}>
                  <Typography textAlign={"right !important"}>
                    {" "}
                    نام فرآیند :
                  </Typography>
                  <TextField
                    value={processName}
                    onChange={(e) => setProcessName(e.target.value)}
                  />
                  <Typography textAlign={"right !important"}>
                    تعداد مراحل فرآیند :
                  </Typography>
                  <TextField
                    type="number"
                    value={processNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value >= 0) {
                        setProcessNumber(value);
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ alignSelf: "end" }}
                    onClick={() => setActiveStep(activeStep + 1)}
                    disabled={!processName || processNumber < 1}
                  >
                    بعدی
                  </Button>
                </Stack>
              ) : (
                <>
                  <Stack
                    flexDirection={"row"}
                    sx={{
                      width: { sm: "70%", xs: "95%" },
                      borderRadius: "15px",
                      boxShadow: "0 0 10px 2px rgba(0,0,0,.2)",
                    }}
                    p={1}
                  >
                    <Typography flex={{ sm: 0.4, xs: 1 }}>
                      شماره مرحله
                    </Typography>
                    <Typography flex={{ sm: 0.4, xs: 1 }}>گیرنده</Typography>
                  </Stack>
                  <Stack
                    sx={{ width: { sm: "70%", xs: "95%" } }}
                    gap={2}
                    pt={1}
                  >
                    {users?.map((_, index) => (
                      <Stack
                        key={index}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography flex={{ sm: 0.4, xs: 1 }}>
                          مرحله {index + 1}
                        </Typography>

                        <Autocomplete
                          size="small"
                          options={options}
                          getOptionLabel={(option) => option?.fullName}
                          loading={loading}
                          onChange={(event, newValue) =>
                            handleUserSelect(index, newValue)
                          }
                          // defaultValue={

                          // }
                          value={
                            options?.filter((e) => {
                              if (e._id == users[index]?.userId) {
                                return e;
                              }
                            })[0]
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="گیرنده"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ direction: "rtl", textAlign: "right" }}
                            >
                              {option?.fullName} - {option?.employeePosition}
                            </li>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "350px",
                              overflowY: "auto",
                            },
                          }}
                          sx={{
                            width: !isMobile ? "300px" : "100%",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            "& input": {
                              textAlign: "right !important",
                              fontSize: "16px",
                            },
                            textAlign: "right !important",
                            direction: "rtl",
                            border: "none",
                            boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                            "& .MuiAutocomplete-listbox": {
                              maxHeight: "350px",
                              overflowY: "auto",
                            },
                            flex: 1,
                          }}
                        />
                      </Stack>
                    ))}
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    sx={{ width: { sm: "70%", xs: "95%" } }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => setActiveStep(activeStep - 1)}
                    >
                      قبلی
                    </Button>
                    <LoadingButton
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={!allUsersSelected()} // غیر فعال کردن دکمه ارسال تا زمانی که همه کاربران انتخاب نشوند
                      loading={btnLoading}
                    >
                      به‌روزرسانی
                    </LoadingButton>
                  </Stack>
                </>
              )}
            </Box>
          ) : (
            <Loader />
          )}
        </Stack>
      </Box>
    </>
  );
}
