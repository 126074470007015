import React, { useEffect, useState } from 'react';
import './gallery.css';
import Footer from '../Footer/Footer';

const GalleryInfo = () => {
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/gallerypage');
        const data = await response.json();
        if (response.ok) {
          setGalleryItems(data.data); // Assuming your API returns an array of gallery items in data.data
        } else {
          console.error('Failed to fetch gallery items', data);
        }
      } catch (error) {
        console.error('Error fetching gallery items:', error);
      }
    };

    fetchGalleryItems();
  }, []);

  return (
    <>
    <div className="masonry-container">
      {galleryItems.map((item, index) => (
    
        <div key={index} className="panel">
          <div className="panel-wrapper">
            <div className="panel-overlay">
              <div className="panel-text">
                <div className="panel-title">{item.title}</div>
                <div className="panel-tags">
                  {/* <span className="tag-icon">
                    <img className="tag-icon-img" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/375042/tag-icon.svg" alt="" />
                  </span> */}
                  <span className="tags-list">{item.description}</span>
                </div>
              </div>
              <img className="panel-gradient" src={item.gradient} alt="" />
              <img className="panel-vingette" src={item.vignette} alt="" />
            </div>
            <img className="panel-img" src={`https://api.asiamehr.com${item.image}`} alt={item.title} />
          </div>
        </div>
      ))}
    </div>
   
    </>
  );
};

export default GalleryInfo;
