import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Stack,
  InputAdornment,
  useMediaQuery,
  Typography
} from "@mui/material";
import PanelSidebar from "../../../Components/Pane/PanelSlidebar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import Loader from "../../../Components/Loader";
import { Link, useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AuthContext } from "../../../utils/AuthContext";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalaliV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin]
});

export const TableItems = ({
  id,
  letterId,
  state,
  letterDate,
  pdf,
  receiver,
  sender,
  subject
}) => {
  return (
    <TableRow>
      <TableCell align="right">
        {pdf ? (
          <a
            target="_blank"
            style={{ color: "black" }}
            href={`https://api.asiamehr.com/uploads/${pdf}`}
            download
          >
            <DownloadIcon />
          </a>
        ) : (
          ""
        )}
        <Link
          style={{ color: "black", margin: "0 10px" }}
          to={`/panel/internal-letter/${id}`}
        >
          <RemoveRedEyeIcon />
        </Link>
      </TableCell>
      <TableCell align="right">{letterDate} </TableCell>
      <TableCell align="right">{receiver?.fullName}</TableCell>
      <TableCell align="right">{sender?.fullName} </TableCell>
      <TableCell align="right">{letterId}</TableCell>
      <TableCell align="right">{subject}</TableCell>
    </TableRow>
  );
};

export default function EnternalSearch() {
  const isMobile = useMediaQuery("(max-width:900px)");
  const [initialLoading, setInitialLoading] = useState(true);
  const { loginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [letters, setLetters] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const convertToGregorianDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let body = {
          search: search,
          type: "internal"
        };
        if (startDate !== new Date()) {
          body.startDate = convertToGregorianDate(startDate);
        }
        if (endDate !== new Date()) {
          body.endDate = convertToGregorianDate(endDate);
        }
        const res = await fetch(
          `https://api.asiamehr.com/api/v1/search?populate=sender,receiver&sort=${sort}&page=${page}&limit=${10}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(body)
          }
        );

        if (!res.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await res.json();
        console.log(data);
        if (data.success) {
          setLetters(data?.data);
          setCount(Math.ceil(data.count / 10));
          console.log(letters);
        } else {
          throw new Error("Error in data");
        }
      } catch (err) {
        console.error("Error:", err);
        alert("اشکال در برقراری اتصال");
      } finally {
        setInitialLoading(false);
      }
    };

    fetchData();
  }, [token, sort, page, search, startDate, endDate]);

  const items = letters.map((e, index) => (
    <TableItems
      key={index}
      id={e?._id}
      letterDate={e?.letterDate}
      letterId={e?.letterId}
      pdf={e?.pdf}
      receiver={e?.receiver}
      state={e?.state}
      sender={e?.sender}
      subject={e?.subject}
    />
  ));

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)"
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0
            }}
          >
            {initialLoading ? (
              <Loader />
            ) : (
              <CacheProvider value={cacheRtl}>
                <Stack
                  flexDirection={"row"}
                  pt={"20px"}
                  sx={{ direction: "rtl !important" }}
                >
                  <Stack
                    dir="rtl"
                    width={"100%"}
                    justifyContent={"space-between"}
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: isMobile ? "100%" : "45%"
                      }}
                    >
                      <TextField
                        placeholder="جستجو"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                        sx={{
                          py: "3.5px",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 10px 2px rgba(255,255,255,.5)",
                          borderRadius: "15px",
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none"
                            },
                            "&:hover fieldset": {
                              border: "none"
                            },
                            "&.Mui-focused fieldset": {
                              border: "none"
                            }
                          }
                        }}
                        dir="rtl"
                      />
                    </Box>
                    <TextField
                      id="outlined-select-currency"
                      select
                      size="small"
                      onChange={(e) => {
                        setSort(e.target.value);
                      }}
                      placeholder="مرتب سازی بر اساس"
                      sx={{
                        width: isMobile ? "100%" : "270px",
                        borderRadius: "15px",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none"
                          },
                          "&:hover fieldset": {
                            border: "none"
                          },
                          "&.Mui-focused fieldset": {
                            border: "none"
                          }
                        }
                      }}
                      defaultValue={"-createdAt"}
                    >
                      <MenuItem dir="rtl" value="-createdAt">
                        جدید ترین نامه ها
                      </MenuItem>
                      <MenuItem dir="rtl" value="createdAt">
                        قدیمی ترین نامه ها
                      </MenuItem>
                    </TextField>
                  </Stack>
                </Stack>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                  px={2}
                  sx={{
                    direction: "ltr",
                    padding: "10px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                  }}
                  gap={1}
                >
                  <Stack
                    direction={{ xl: "row", xs: "column" }}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                    px={2}
                    sx={{ width: isMobile ? "100%" : "45%", direction: "ltr" }}
                  >
                    <Typography>
                      {isMobile ? "از تاریخ :" : "نمایش نامه ها از تاریخ :"}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                      <DatePicker
                        mask="____/__/__"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          console.log(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "white",
                                "& fieldset": {
                                  borderColor: "#ccc"
                                },
                                "&:hover fieldset": {
                                  borderColor: "#aaa"
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1976d2"
                                }
                              },
                              "& .MuiInputAdornment-root": {
                                marginRight: "8px"
                              }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack
                    direction={{ xl: "row", xs: "column" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    sx={{ width: isMobile ? "100%" : "45%", direction: "ltr" }}
                  >
                    {" "}
                    <Typography>
                      {!isMobile ? "نمایش نامه ها " : ""}تا تاریخ :
                    </Typography>{" "}
                    <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                      <DatePicker
                        mask="____/__/__"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                backgroundColor: "white",
                                "& fieldset": {
                                  borderColor: "#ccc"
                                },
                                "&:hover fieldset": {
                                  borderColor: "#aaa"
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1976d2"
                                }
                              },
                              "& .MuiInputAdornment-root": {
                                marginRight: "8px"
                              }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Stack>
                <TableContainer
                  sx={{
                    marginTop: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    overflowX: "auto" // اضافه شده برای اسکرول افقی
                  }}
                  component={Paper}
                >
                  <Table
                    sx={{
                      direction: "rtl",
                      minWidth: "800px",
                      borderCollapse: "collapse"
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          عملیات
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          تاریخ
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          گیرنده
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          فرستنده
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          شماره نامه
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          موضوع
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items && items.length > 0 ? (
                        items
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            align="center"
                            sx={{
                              padding: "20px",
                              fontStyle: "italic",
                              color: "#888"
                            }}
                          >
                            نامه ای موجود نیست
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  siblingCount={0}
                  boundaryCount={0}
                  defaultPage={1}
                  count={count}
                  page={page}
                  onChange={(e, value) => setPage(value)}
                  color="primary"
                  sx={{
                    marginTop: 3,
                    marginBottom: 3,
                    display: "flex",
                    "& .MuiPaginationItem-root": {
                      bgcolor: "white",
                      borderRadius: 2,
                      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.2)",
                      "&:hover": {
                        bgcolor: "grey.200"
                      }
                    },
                    "& .Mui-selected": {
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.dark"
                      }
                    }
                  }}
                />
              </CacheProvider>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
