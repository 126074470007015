import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputLabel,
  FormControl,
  Paper,
  Pagination,
  Typography,
  useMediaQuery,
  Stack,
  Input,
  InputAdornment,
} from "@mui/material";
import PanelSidebar from "../../../Components/Pane/PanelSlidebar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { Link, Navigate, useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { AuthContext } from "../../../utils/AuthContext";
import Loader from "../../../Components/Loader";
import SearchIcon from "@mui/icons-material/Search";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export const TableItems = ({
  id,
  letterId,
  state,
  letterDate,
  pdf,
  receiver,
}) => {
  return (
    <TableRow>
      <TableCell align="right">
        {pdf ? (
          <a
            target="_blank"
            style={{ color: "black" }}
            href={`https://api.asiamehr.com/uploads/${pdf}`}
            download
          >
            <DownloadIcon />
          </a>
        ) : (
          ""
        )}
        <Link
          style={{ color: "black", margin: "0 10px" }}
          to={`/panel/external-letter/${id}`}
        >
          <RemoveRedEyeIcon />
        </Link>
      </TableCell>
      <TableCell align="right">
        {state == "Pending"
          ? "در انتظار تایید"
          : state == "Done"
          ? "تایید شده"
          : "رد شده"}
      </TableCell>
      <TableCell align="right">{letterDate} </TableCell>
      <TableCell align="right">{receiver}</TableCell>
      <TableCell align="right">{letterId}</TableCell>
    </TableRow>
  );
};

export default function TerminatedLetter() {
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const { token = "" } = loginUser;
  const [letters, setLetters] = useState(null);
  const [sort, setSort] = useState("-createdAt");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [searchLetter, setSearchLetter] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:900px)");
  // search letters
  // useEffect(() => {
  //   (async () => {
  //     // const res = await fetch(`https://api.asiamehr.com/api/v1/externalLetter?search[letterId]=${search}`);
  //     // const data = await res.json();

  //     const items = array?.map((e, index) => (
  //       <MenuItem key={index} onClick={() => { navigate(`/panel/external-letter/search/${e}/TerminatedLetter`) }}>
  //         {e}
  //       </MenuItem>
  //     )) || [];
  //     // setSearchResults(items);
  //   })()
  // }, [search]);
  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          `https://api.asiamehr.com/api/v1/externalLetter/terminated?populate=sender&sort=${sort}&page=${page}&limit=${10}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        console.log(data);
        if (data.success) {
          setLetters(data.data);
          console.log(data.letterCount);
          setCount(Math.ceil(data.count / 10));
        } else {
          alert("اشکال در برقراری اتصال");
        }
      } catch (err) {
        alert("اشکال در برقراری اتصال");
      }
    })();
  }, [token, sort, page]);
  const items = letters?.map((e, index) => (
    <TableItems
      key={index}
      id={e._id}
      letterDate={e?.letterDate}
      letterId={e?.letterId}
      pdf={e?.pdf}
      receiver={e?.receiver}
      state={e?.state}
    />
  ));
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              overflowX: "scroll",
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0,
            }}
          >
            {letters ? (
              <>
                <CacheProvider value={cacheRtl}>
                  <Stack
                    flexDirection={"row"}
                    pt={"20px"}
                    sx={{ direction: "rtl !important" }}
                  >
                    <Stack
                      dir="rtl"
                      width={"100%"}
                      justifyContent={"space-between"}
                      flexDirection={isMobile ? "column" : "row"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Box sx={{ position: "relative", width:isMobile ?"100%" : "250px" }}>
                        <TextField
                          placeholder="جستجو"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            py: "3.5px",
                            backgroundColor: "white",
                            boxShadow: "0px 0px 10px 2px rgba(255,255,255,.5)",
                            borderRadius: "15px",
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                            },
                          }}
                          dir="rtl"
                        />
                      </Box>

                      <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        onChange={(e) => setSort(e.target.value)}
                        placeholder="مرتب سازی بر اساس"
                        sx={{
                          width: isMobile?"100%":"270px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                        defaultValue={"s"}
                      >
                        <MenuItem dir="rtl" value="s">
                          مرتب سازی بر اساس
                        </MenuItem>
                        <MenuItem dir="rtl" value="-createdAt">
                          جدید ترین نامه ها
                        </MenuItem>
                        <MenuItem dir="rtl" value="createdAt">
                          قدیمی ترین نامه ها
                        </MenuItem>
                      </TextField>
                    </Stack>
                  </Stack>

                  <TableContainer
                    sx={{
                      marginTop: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      overflowX: "auto", // اضافه شده برای اسکرول افقی
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        direction: "rtl",
                        minWidth: "800px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            عملیات
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            وضعیت
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            تاریخ
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            گیرنده
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            شماره نامه
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items && items.length > 0 ? (
                          items
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={5}
                              align="center"
                              sx={{
                                padding: "20px",
                                fontStyle: "italic",
                                color: "#888",
                              }}
                            >
                              نامه ای موجود نیست
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Pagination
                    siblingCount={0}
                    boundaryCount={0}
                    defaultPage={1}
                    count={count}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    color="primary"
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                      display: "flex",
                      "& .MuiPaginationItem-root": {
                        bgcolor: "white",
                        borderRadius: 2,
                        boxShadow: "0 1px 5px rgba(0, 0, 0, 0.2)",
                        "&:hover": {
                          bgcolor: "grey.200",
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      },
                    }}
                  />
                </CacheProvider>
              </>
            ) : (
              <Loader />
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
