import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../../utils/AuthContext'
import { Box, Typography, Avatar, Stack } from '@mui/material'

import Employees from './Employe'

function Panel() {
    const location = useLocation()
    const { loginUser } = useContext(AuthContext)

    return (
        !loginUser?.token ? <Navigate to="/login" /> :
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, row on larger screens
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: 'linear-gradient(135deg, rgba(0, 0, 50, 0.8), rgba(0, 0, 100, 0.9))',
                padding: { xs: 2, md: 3 }, // Add padding on small screens and larger
            }}
        >
            {/* Left panel content */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                    width: '100%',
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: 'background.paper',
                    textAlign: 'center',
                    zIndex: 1,
                    marginBottom: { xs: 2, md: 0 }, 
                    direction:'rtl',
                    // Add bottom margin on smaller screens
                }}
            >
                <Typography variant="h5" textAlign="center" mb={3}>
                    خوش آمدید به پنل کاربری
                </Typography>
                <Stack spacing={3} alignItems="center">
                    {/* Profile Picture */}
                    <Avatar
                        src={loginUser?.profilePic ? `https://api.asiamehr.com/uploads/${loginUser.profilePic}` : '/default-avatar.png'}
                        alt="User Profile Picture"
                        sx={{ width: 100, height: 100 }}
                    />
                    <Typography variant="h6">{loginUser?.fullName || 'نام کاربر'}</Typography>

                    {/* User Information */}
                    <Typography variant="body1"><strong>ایمیل:</strong> {loginUser?.email || 'ایمیل ثبت نشده'}</Typography>
                    <Typography variant="body1"><strong>کد پرسنلی:</strong> {loginUser?.personalCode|| 'آدرس ثبت نشده'}</Typography>
                    {/* <Typography variant="body1"><strong>نقش کاربر:</strong> {loginUser?.employeePosition || 'نقش ثبت نشده'}</Typography> */}
                    <Typography variant="body1"><strong>شماره تماس:</strong> {loginUser?.phone || 'شماره ثبت نشده'}</Typography>
                    <Typography variant="body1"><strong>آدرس:</strong> {loginUser?.address || 'آدرس ثبت نشده'}</Typography>
                </Stack>
            </Box>

            {/* Sidebar */}
            <Box>
                <Employees />
            </Box>
        </Box>
    )
}

export default Panel
