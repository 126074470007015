import React, { useEffect, useState ,useContext } from 'react';
import { useParams } from "react-router-dom";
import './update.css'
import SliderPanel from '../SliderPanel';
import PanelSidebar from '../PanelSlidebar';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../utils/AuthContext';

function SliderChange() {
  const [sliderData, setSliderData] = useState([]);
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const { id } = useParams();
  const { loginUser } = useContext(AuthContext)
  useEffect(() => {
    fetch(`https://api.asiamehr.com/api/v1/slider`)
      .then((res) => res.json())
      .then((data) => setSliderData(data.data))
      .catch((err) => console.log(err));
  }, [id]);

  const deleteItem = (id) => {
    fetch(`https://api.asiamehr.com/api/v1/slider/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setSliderData(sliderData.filter(slider => slider._id !== id));
      })
      .catch((err) => console.log(err));
      
  };

  return (
    !loginUser?.token ? <Navigate to="/login" /> :
    <div  className='myslider-panel' >
        <h1> حذف کردن اطلاعات </h1>
        <PanelSidebar/>
      {sliderData?.map(slider => (
        <div className='slide'  key={slider._id}>
          <h3>{slider.title}</h3>
          <img src={`https://api.asiamehr.com${slider.image}`} alt={slider.title} />
          <p>{slider.description}</p>
          <button onClick={() => deleteItem(slider._id)}>حذف</button>
        </div>
      ))}
    </div>
  );
}

export default SliderChange;

