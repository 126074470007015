import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Box,
  Button,
  Input,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PanelSidebar from "./PanelSlidebar";
import { AuthContext } from "../../utils/AuthContext";

const EmailForm = () => {
  const [emailData, setEmailData] = useState({ to: "", subject: "", text: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const { loginUser } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width:900px)");

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const sendEmail = async () => {
    if (!emailData.to || !emailData.subject || !emailData.text) {
      setAlertMessage("لطفاً تمامی فیلدها را پر کنید");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    try {
      const res = await fetch("https://api.asiamehr.com/api/v1/sendemail", {
        method: "POST",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${loginUser.token}`,
        },
        body: JSON.stringify(emailData),
      });

      const data = await res.json();

      if (res.status === 200) {
        setAlertMessage("ایمیل با موفقیت ارسال شد.");
        setAlertSeverity("success");
      } else {
        throw new Error("ناموفق");
      }
    } catch (err) {
      console.error(err);
      setAlertMessage("خطا در ارسال ایمیل");
      setAlertSeverity("error");
    } finally {
      setAlertOpen(true);
    }
  };

  if (!loginUser?.token) return <Navigate to="/login" />;
  if (loginUser.role !== "admin") {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background:
            "linear-gradient(180deg, rgba(0,31,63,1) 0%, rgba(0,81,165,1) 100%)",
        }}
      >
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="flex-end"
        >
          <PanelSidebar />
          <Stack
            sx={{
              width: isMobile ? "100%" : "80%",
              minHeight: "100vh",
              marginTop: 10,
              marginRight: isMobile ? 0 : 35,
              padding: isMobile ? 2 : 0,
            }}
          >
            <Box
              sx={{
                padding: "50px 5%",
                borderRadius: "20px",
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                my={"20px"}
                color={"gainsboro"}
              >
                ارسال ایمیل
              </Typography>

              <Stack
                flexDirection={!isMobile ? "row" : "column"}
                flexWrap={"wrap"}
                gap={2}
                width={"100%"}
              >
                <TextField
                  placeholder="به"
                  value={emailData.to}
                  onChange={(e) =>
                    setEmailData({ ...emailData, to: e.target.value })
                  }
                  required
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& input": {
                      textAlign: "right !important",
                      fontSize: "16px",
                    },
                    textAlign: "right !important",
                    direction: "rtl",
                    boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <TextField
                  placeholder="موضوع"
                  value={emailData.subject}
                  onChange={(e) =>
                    setEmailData({ ...emailData, subject: e.target.value })
                  }
                  required
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    "& input": {
                      textAlign: "right !important",
                      fontSize: "16px",
                    },
                    textAlign: "right !important",
                    direction: "rtl",
                    border: "none",
                    boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Stack>

              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow: "0 0 10px rgba(0,0,0,.3)",
                  minHeight: "300px",
                }}
              >
                <textarea
                  className="slider-textarea"
                  placeholder="پیام"
                  value={emailData.text}
                  onChange={(e) =>
                    setEmailData({ ...emailData, text: e.target.value })
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    outline: "none",
                    padding: "10px",
                    borderRadius: "10px",
                    fontSize: "16px",
                    fontFamily: "inherit",
                    backgroundColor: "transparent",
                  }}
                ></textarea>
              </Box>

              <Box sx={{ textAlign: "left !important" }}>
                <Button
                  type="button"
                  variant="contained"
                  color="success"
                  onClick={sendEmail}
                >
                  ارسال ایمیل
                </Button>
              </Box>

              <Link to="https://webmail.asiamehr.com/" style={{ marginTop: '20px', color: '#fff' }}  >  ورود به وب‌میل</Link>

              <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
              >
                <MuiAlert
                  onClose={handleCloseAlert}
                  severity={alertSeverity}
                  sx={{ width: "100%" }}
                >
                  {alertMessage}
                </MuiAlert>
              </Snackbar>
            </Box>
          </Stack>
        </Stack>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default EmailForm;
