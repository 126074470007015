import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import PanelSidebar from "./PanelSlidebar";
import { AuthContext } from "../../utils/AuthContext";
import "./panel.css";
import { Navigate } from "react-router-dom";
export default function Users() {
  const { loginUser } = useContext(AuthContext);
  const [userRows, setUserRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [updateFields, setUpdateFields] = useState({
    fullName: "",
    phone: "",
    email: "",
    role: "",
    employeePosition: "",
    profilePic: null,
    signature: null,
    gender:"male"
  });
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (loginUser?.token) {
      fetch("https://api.asiamehr.com/api/v1/user", {
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${loginUser?.token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            setUserRows(data.data);
          } else {
            console.error("Failed to fetch users:", data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [loginUser?.token]);

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setUpdateFields({
      fullName: user.fullName,
      phone: user.phone,
      email: user.email,
      role: user.role,
      gender: user.gender,
      employeePosition: user.employeePosition,
      profilePic: null,
      signature: null,
      personalCode: user.personalCode,
    });
    setOpenDialog(true);
  };

  const openDeleteDialog = (userId) => {
    setSelectedUserId(userId);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedUserId(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedUserId) return;

    try {
      const response = await fetch(
        `https://api.asiamehr.com/api/v1/user/${selectedUserId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${loginUser?.token}`,
          },
        }
      );

      if (response.ok) {
        setUserRows(userRows.filter((user) => user._id !== selectedUserId));
        setSnack({
          open: true,
          message: "کاربر با موفقیت حذف شد",
          severity: "success",
        });
      } else {
        throw new Error("Error deleting user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      setSnack({
        open: true,
        message: "خطا در حذف کاربر",
        severity: "error",
      });
    } finally {
      setDeleteDialogOpen(false);
      setSelectedUserId(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFields({ ...updateFields, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUpdateFields({ ...updateFields, profilePic: file });
  };

  const handleSignatureChange = (e) => {
    const file = e.target.files[0];
    setUpdateFields({ ...updateFields, signature: file });
  };

  const handleUpdateSubmit = async () => {
    const formData = new FormData();
    formData.append("fullName", updateFields.fullName);
    formData.append("phone", updateFields.phone);
    formData.append("email", updateFields.email);
    formData.append("role", updateFields.role);
    formData.append("gender", updateFields.gender);
    formData.append("personalCode", updateFields.personalCode);
    formData.append("employeePosition", updateFields.employeePosition);
    if (updateFields.profilePic) {
      formData.append("file", updateFields.profilePic);
    }
    if (updateFields.signature) {
      formData.append("signature", updateFields.signature);
    }

    try {
      const response = await fetch(
        `https://api.asiamehr.com/api/v1/user/${selectedUser._id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${loginUser?.token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUserRows(
          userRows.map((user) =>
            user._id === selectedUser._id ? updatedUser.data : user
          )
        );
        setSnack({
          open: true,
          message: "کاربر با موفقیت بروزرسانی شد",
          severity: "success",
        });
        setOpenDialog(false);
      } else {
        throw new Error("Error updating user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setSnack({
        open: true,
        message: "خطا در بروزرسانی کاربر",
        severity: "error",
      });
    }
  };
  if (!loginUser?.token) return <Navigate to="/login" />;
  return (
    <>
      <Stack
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="flex-end"
      >
        <PanelSidebar />
        <Stack
          sx={{
            width: isMobile ? "100%" : "80%",
            height: "100vh",
            marginTop: 10,
            marginRight: isMobile ? 0 : 35,
            padding: isMobile ? 2 : 0,
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ height: isMobile ? "auto" : "80vh", overflowX: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>نام و نام خانوادگی</TableCell>
                  <TableCell>موبایل</TableCell>
                  <TableCell>کد پرسنلی </TableCell>
                  <TableCell>ایمیل</TableCell>
                  <TableCell>جنسیت</TableCell>
                  <TableCell>نقش</TableCell>
                  <TableCell>سمت</TableCell>
                  <TableCell>عکس پروفایل</TableCell>
                  <TableCell>امضا</TableCell>
                  <TableCell>عملیات</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userRows &&
                  userRows.map((user) => (
                    <TableRow key={user._id}>
                      <TableCell>{user.fullName}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>{user.personalCode}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.gender}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.employeePosition}</TableCell>
                      <TableCell>
                        {user.profilePic ? (
                          <img
                            src={`https://api.asiamehr.com${user.profilePic}`}
                            alt="Profile Pic"
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <Typography>عکسی یافت نشد</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.signature && (
                          <img
                            src={`https://api.asiamehr.com${user.signature}`}
                            alt="User Signature"
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdate(user)}
                        >
                          ویرایش
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => openDeleteDialog(user._id)}
                        >
                          حذف
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Dialog open={deleteDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>حذف کاربر</DialogTitle>
        <DialogContent>
          <DialogContentText>
            آیا مطمئن هستید که می‌خواهید این کاربر را حذف کنید؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            لغو
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            حذف
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>بروزرسانی کاربر</DialogTitle>
        <DialogContent>
          <DialogContentText>
            لطفا اطلاعات کاربر را بروزرسانی کنید.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="fullName"
            placeholder="نام و نام خانوادگی"
            type="text"
            fullWidth
            value={updateFields.fullName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phone"
            placeholder="موبایل"
            type="text"
            fullWidth
            value={updateFields.phone}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="personalCode"
            placeholder="کد پرسنلی "
            type="text"
            fullWidth
            value={updateFields.personalCode}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            placeholder="ایمیل"
            type="email"
            fullWidth
            value={updateFields.email}
            onChange={handleInputChange}
          />
           <Select
            name="gender"
            value={updateFields.gender}
            onChange={handleInputChange}
            fullWidth
            displayEmpty
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              direction: "rtl",
              textAlign: "right !important",
              boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused fieldset": { border: "none" },
              },
            }}
          >
            <MenuItem value="male">آقا</MenuItem>
            <MenuItem value="female">خانم</MenuItem>
          </Select>
          <Select
            name="role"
            value={updateFields.role}
            onChange={handleInputChange}
            fullWidth
            displayEmpty
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              direction: "rtl",
              textAlign: "right !important",
              boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused fieldset": { border: "none" },
              },
            }}
          >
            <MenuItem value="admin">ادمین</MenuItem>
            <MenuItem value="superAdmin">سوپر ادمین</MenuItem>
            <MenuItem value="employee">کارمند</MenuItem>
          </Select>
          <Select
            name="employeePosition"
            value={updateFields.employeePosition}
            onChange={handleInputChange}
            fullWidth
            displayEmpty
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              direction: "rtl",
              textAlign: "right !important",
              boxShadow: "0 0 10px 2px rgba(255,255,255,.6)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { border: "none" },
                "&:hover fieldset": { border: "none" },
                "&.Mui-focused fieldset": { border: "none" },
              },
            }}
          >
            <MenuItem value="مدیر عامل">مدیر عامل</MenuItem>
            <MenuItem value="رییس هیت مدیره">رییس هیت مدیره</MenuItem>
            <MenuItem value="نایب رییس هیت مدیره">نایب رییس هیت مدیره</MenuItem>
            <MenuItem value="بازرس">بازرس</MenuItem>
            <MenuItem value="مدیر مالی">مدیر مالی</MenuItem>
            <MenuItem value="مدیر دفتری">مدیر دفتری</MenuItem>
            <MenuItem value="مدیر حقوقی">مدیر حقوقی</MenuItem>
            <MenuItem value="مدیر فنی">مدیر فنی</MenuItem>
            <MenuItem value="مدیر رسانه">مدیر رسانه</MenuItem>
            <MenuItem value="سرپرست کارگاه">سرپرست کارگاه</MenuItem>
            <MenuItem value="کارگر">کارگر</MenuItem>
            <MenuItem value="نگهبان">نگهبان</MenuItem>
            <MenuItem value="پشتیبان سایت">پشتیبان سایت</MenuItem>
            <MenuItem value="منابع انسانی">منابع انسانی</MenuItem>
            <MenuItem value="مدیر تحقیقاتی">مدیر تحقیقاتی</MenuItem>
            <MenuItem value="مشاور">مشاور</MenuItem>
            <MenuItem value="مدیر حوزه مدیریت">  مدیر حوزه مدیریت </MenuItem>
          </Select>
          <Button variant="outlined" component="label">
            عکس پروفایل
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <Button variant="outlined" component="label">
            امضا
            <input type="file" hidden onChange={handleSignatureChange} />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            لغو
          </Button>
          <Button onClick={handleUpdateSubmit} color="secondary">
            بروزرسانی
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack.severity}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
}
