// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Facebook, Twitter, YouTube, LinkedIn } from '@mui/icons-material';
import './Footer.css'
import { Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram'; 
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Qrcode from '../Main/Qrcode';
function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-content">
        <h3>{t('footer.title')}</h3>
        <div  className='contact-info2'>
        <Typography variant="h5" gutterBottom>{t('contact.title')}</Typography>
        <Typography variant="body1" gutterBottom>{t('contact.address_line2')}</Typography>
          <Typography variant="body2" gutterBottom>{t('contact.address')}</Typography>
      
         {/* <div className='code'>
         <Qrcode/>
         </div>  */}
          </div>
      
        <ul className="socials"> 
       
          <li><Link to="https://www.instagram.com/asiamehr_company/"><InstagramIcon /></Link></li>
          <li><Link to="https://www.linkedin.com/in/asiamehr"><LinkedIn /></Link></li>
          <li><Link to="tel:9000090"><PhoneIcon /></Link></li>
          <li><Link to="mailto:asiamehrfs@gmail.com"><AlternateEmailIcon /></Link></li>
        
        </ul>
      </div>
      <div className="footer-bottom">
        <p>{t('footer.copyright')} <Link to="#">{t('footer.title')}</Link></p>
        <div className="footer-menu">
          <ul className="f-menu">
            <li><Link to="/">{t('footer.home')}</Link></li>
            <li><Link to="/about">{t('footer.about')}</Link></li>
            <li><Link to="/contact">{t('footer.contact')}</Link></li>
            <li><Link to="/gallery">{t('footer.blog')}</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
